import { FC, useMemo } from "react";
import { Controller } from "react-hook-form";
import { TestRadio } from "types/testProcess.types";
import Checkbox from "common/UIKit/Checkbox";
import { useTest } from "hooks/useTest";
import styles from "./Radio.module.scss";
import { useResponsive } from "hooks/useResponsive";

interface Props {
  question: TestRadio;
  disabled?: boolean;
}

export const Radio: FC<Props> = ({ question, disabled }) => {
  const { control, errors } = useTest();
  const { isMobile } = useResponsive();

  const gridTemplate = useMemo(
    () =>
      isMobile
        ? {
            gridTemplateRows: `repeat(${question.options.length}, 1fr)`,
          }
        : {
            gridTemplateColumns: `repeat(${question.options.length}, 1fr)`,
          },
    [question, isMobile]
  );

  return (
    <Controller
      control={control}
      name={question.id.toString()}
      rules={{
        required: {
          value: question.required,
          message: "This field is required",
        },
      }}
      render={({ field: { value, onChange, ref } }) => (
        <div className={styles.root}>
          <div className={styles.row} style={gridTemplate}>
            {question.options.map((option) => (
              <div className={styles.item} key={option.value}>
                <div className={styles.label}>{option.label}</div>
                <Checkbox
                  className={styles.checkbox}
                  id={`${question.id}-${option.value}`}
                  value={option.value}
                  type="radio"
                  checked={option.value === value}
                  onChange={() => onChange(option.value)}
                  ref={ref}
                  disabled={disabled}
                >
                  {String(option.value)}
                </Checkbox>
              </div>
            ))}
          </div>
          {errors[question.id] && (
            <p className={styles.error}>{errors[question.id]?.message}</p>
          )}
        </div>
      )}
    />
  );
};
