import {
  AgeCode,
  Availability,
  Ethnicity,
  Faith,
  State,
} from "constants/options";
import { BaseUser, UserId } from "./auth.types";
import { CredentialType } from "./credential.types";
import { Plan } from "./plan.types";
import { Subscription } from "./subscription.types";

export type TherapistId = string;

export const ALL_TITLES = ["mr", "mrs", "miss", "ms", "dr"] as const;

export enum ModalitiesEnum {
  individuals = "individuals",
  couples = "couples",
  family = "family",
  group = "group",
}

export type Modality = `${ModalitiesEnum}`;

export type Title = typeof ALL_TITLES[number];
export type Credential = "ma" | "phd" | "lcsw";
export type Gender = "female" | "male" | "non_binary";
export type Group =
  | "a_professionals"
  | "bind_allied"
  | "body_positivity"
  | "cancer"
  | "deaf_allied"
  | "gay_allied"
  | "hiv_or_aids_allied"
  | "immuno_disorders"
  | "intersex_allied"
  | "lesbian_allied"
  | "little_person_allied"
  | "non_binary_allied"
  | "open_relationships"
  | "queer_allied"
  | "racial_justice_allied"
  | "sex_worker_allied"
  | "sex_positive"
  | "single_mother"
  | "transgender_allied"
  | "vegan_allied"
  | "veterans";

export enum FormOfPaymentEnum {
  cash = "cash",
  visa = "visa",
  masterCard = "mastercard",
  americanExpress = "americanexpress",
  zellePay = "zelle",
  venmo = "venmo",
}

export type FormOfPayment = `${FormOfPaymentEnum}`;

export type FileType = "credential" | "photo_and_video_intro" | "claim_profile";

export type TherapistFile = {
  type: FileType;
  id: number;
  file: string;
  mime: string;
  s3_upload_presign: string;
  s3_download_presign: string;
  is_main_photo: boolean;
};
export type PrimaryCredentialType =
  | "i_am_licensed"
  | "i_am_pre_licensed"
  | "i_have_no_license";

// AVAILABLE = , _("Available")
// WAIT =  _("Wait")
// OFFLINE = , _("Offline")

export type ChatStatus = "available" | "wait" | "offline";

export interface Therapist {
  id: TherapistId;
  user: BaseUser;
  professional_specialties?: readonly string[];
  title: Title;
  middle_name?: string;
  credentials_choice?: string;
  gender?: Gender;
  company_name?: string;
  website?: string;
  ages: readonly AgeCode[];
  allied_groups: readonly Group[];
  preferred_ethnicities: readonly Ethnicity[];
  insurance_companies?: readonly string[];
  npi_number: string | null;
  malpractice_carrier: string;
  membership_id: string;
  personal_statement_short_description: string;
  personal_statement_how_can_help: string;
  photos: readonly TherapistFile[];
  latitude: number;
  longitude: number;
  street_address: string;
  city: string;
  zip_code: string;
  state: State;
  phone: string;
  phone_extension: string;
  hide_street_address: boolean;
  subscription_plan: Plan;
  current_subscription: {
    monthly: Subscription | null;
    yearly: Subscription | null;
  };
  issues: readonly string[];
  therapies: readonly string[];
  modalities: readonly Modality[];
  specialties: readonly string[];
  consent_accepted: boolean;

  preferred_languages: readonly string[];
  preferred_faith: Faith;

  // Fees
  form_of_payment: readonly FormOfPayment[];

  in_person_session_cost_min: number | null;
  in_person_session_cost_max: number | null;

  online_individual_session_cost_min: number | null;
  online_individual_session_cost_max: number | null;

  // step 3_1
  primary_credential_type: PrimaryCredentialType;
  verified_credential_type: CredentialType;
  // step 3_2
  school_graduated: string;
  degree_diploma: string;
  year_graduated: number | null;
  how_long_practicing: number;
  about_qualifications: string;
  in_person_availability: Availability;

  online_availability: Availability;

  offer_free_call: boolean;
  // fields from profile
  telephone_session_availability: boolean;
  video_session_availability: boolean;
  in_person_session_availability: boolean;
  online_chat_sessions_availability: boolean;
  chat_status: ChatStatus;
  is_my_favourite: boolean;
  mental_health_role: string;
  license_number: string;
  license_state: string;
  license_expiration: string | null;
  supervisor_license_number: string;
  supervisor_license_state: string;
  supervisor_license_expiration: string | null;
  supervisor_name: string;
  main_other_credential_for_no_license: OtherCredentials | null;
  primary_credential_is_verified: boolean;
  stripe_status: StripeStatus;
  business_info: BusinessInfo;
  send_system_macros_notification: boolean;
  send_custom_macros_notification: boolean;
  hide_personal_address: boolean;
  source: ImportSource;
}

type ImportSource =
  | "registered"
  | "imported_pt"
  | "imported_bh"
  | "imported_ft"
  | "claimed";

export interface BusinessInfo {
  therapist: string;
  use_company_name_also: boolean;
  company_name: string;
  website: string;
  hide_company_address: boolean;
  street_address: string;
  city: string;
  state: string;
  zip_code: string;
  hide_company_phone_number: boolean;
  phone: string;
  phone_extension: string;
  longitude: number;
  latitude: number;
}

export type StripeStatus = "disconnected" | "not_completed" | "completed";
export type TherapistList = TherapistListItem[];

export type StateLicense = { state: string; number: string };

export interface BaseTherapistItem {
  id: TherapistId;
  photos: readonly TherapistFile[];
  first_name: string;
  last_name: string;
  middle_name: string;
  title: Title;
  credentials_choice: string;
  gender: Gender;
  primary_credential_is_verified: boolean;
  use_company_name_also: boolean;
  company_name: string;
  street_address: string;
  city: string;
  state: string;
  zip_code: string;
  mobile_phone: string;
  hide_phone_number: boolean;
  phone: string;
  phone_extension: string;
  personal_statement_short_description: string;
  personal_statement_how_can_help: string;
  is_my_favourite: boolean;
  in_person_session_availability: boolean;
  telephone_session_availability: boolean;
  video_session_availability: boolean;
  online_chat_sessions_availability: boolean;
  chat_status: ChatStatus;
  subscription_plan: Plan;
  professional_specialties?: readonly string[];
  user_id: UserId;
  hide_mobile_phone_number: boolean;
  source: ImportSource;
  business_info: BusinessInfo;
}

export interface TherapistListItem extends BaseTherapistItem {
  verified_state_licenses: readonly StateLicense[];
}

export interface TherapistFeaturedItem extends BaseTherapistItem {
  verified_state_licenses: readonly StateLicense[];
}

export interface THerapistQuery {
  how_long_practicing?: string;
  in_person_cost_max?: string;
  in_person_cost_min?: string;
  issues?: string;
  latitude?: string;
  longitude?: string;
  limit?: string;
  miles?: string;
  offset?: string;
  online_cost_max?: string;
  online_cost_min?: string;
  therapies?: string;
}

export interface TherapistEditFields {
  is_my_favourite?: string | boolean;
}

export interface TherapistStats {
  total_contacts: number;
  profile_completion: number;
  sms: number;
  email: number;
}

export interface OtherCredentials {
  id?: number;
  type?: CredentialType;
  year?: number;
  membership_organization?: string;
  membership_id?: string;
  license_issuer?: string;
  license_number?: string;
  accrediting_institution?: string;
  certificate_number?: string;
  diploma_school_graduated?: string;
  diploma_degree_type?: string;
}
