import { ETHNICITY_OPTIONS } from "constants/options";
import { DictionaryItem } from "services/dictionaryService";
import { communicationService } from "services/communicationService";
import { Therapist, TherapistListItem } from "types/therapist.types";

export const ETHNICITY_OPTIONS_WITHOUT_NONE = ETHNICITY_OPTIONS.map((o) =>
  o.value === "none" ? { ...o, label: "Other Racial or Ethnic Background" } : o
);

export const filterDictionaryItems = (
  dictionaryItems: DictionaryItem[],
  entities?: readonly string[]
) => dictionaryItems && dictionaryItems.filter((item) => (entities || []).includes(item.code));

export const filterOneDictionaryItem = (
  dictionaryItems: DictionaryItem[],
  entity?: string
) => dictionaryItems.filter((item) => entity?.match(item.code))[0];

export const findOption = (
  element: string,
  array: any[],
  key: string
): string => {
  const hasElement =
    element &&
    array.find((item) =>
      item.value.toLowerCase().match(element.toLowerCase())
    )?.[key];

  return hasElement ? hasElement : element;
};

export const serializeQuery = function (object: { [x: string]: any }) {
  const data = [];

  for (const p in object)
    if (object.hasOwnProperty(p)) {
      const item = object[p] || "";
      data.push(encodeURIComponent(p) + "=" + encodeURIComponent(item));
    }
  return data.join("&");
};

export const openMessageModalAfterLogin = (profile: Therapist | TherapistListItem | null = null) => {
  if (profile) {
    const isMessageModalOpen = localStorage.getItem("isMessageModalOpen");
    const therapistIdForOpenModal = localStorage.getItem('therapistIdForOpenModal');
    const currentTherapist = profile.id === therapistIdForOpenModal ? profile : null
    if (isMessageModalOpen === "true" && currentTherapist) {
      localStorage.removeItem("isMessageModalOpen");
      localStorage.removeItem("therapistIdForOpenModal");
      communicationService.openPopup(currentTherapist);
    }
  }
}
