import { useSnapshot } from "valtio";
import { useNavigate } from "react-router-dom";

import { testResultLevelToColor } from "common/utils";

import { testAttemptService } from "services/testAttemptService";
import { testProcessService } from "services/testProcessService";

import { useResponsive } from "hooks/useResponsive";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";

import Button from "common/UIKit/Button";
import { EditHeader } from "common/components/EditHeader";
import { TestScore } from "common/components/TestScore";

import testImage from "assets/images/test.png";
import { ReactComponent as LightBulb } from "assets/images/icons/lightbulb.svg";

import { testsPreparePath } from "../TestPrepare";

import styles from "./TestResult.module.scss";
import classNames from "classnames";

export const testsResultPath = "/result";

export const TestResult = () => {
  const { test } = useSnapshot(testProcessService);
  const navigate = useNavigate();
  const { isMobile } = useResponsive();
  useDashboardNavigation(test?.name || "Unknown Test", {
    isNavigationHidden: true,
    goBack: () => navigate("./../.."),
  });
  const { entity: currentAttempt } = useSnapshot(testAttemptService);

  const takeTestAgain = async () => {
    if (!currentAttempt) return;

    navigate(`../../new${testsPreparePath}?test_id=${currentAttempt.test.id}`);
  };

  if (!currentAttempt) return null;

  const isRawText = currentAttempt.result_details?.result_type === "raw_text";

  return (
    <div className={styles.testResult}>
      <EditHeader withoutBack />
      <h2>Results</h2>
      <div className={styles.row}>
        {!isRawText && (
          <TestScore
            score={currentAttempt.result_details?.level || 1}
            maxScore={currentAttempt.result_details?.total_levels || 1}
            radius={180}
            segmentWidth={16}
            segmentSeparation={0.5}
            backdropRadius={isMobile ? 75 : 120}
            className={styles.speedometer}
          />
        )}
        <div
          className={classNames(styles.scoreCard, {
            [styles.raw_text]: isRawText,
          })}
        >
          {!isRawText && (
            <b
              className={styles.score}
              style={{
                color: testResultLevelToColor(
                  currentAttempt.result_details?.level || 1,
                  currentAttempt.result_details?.total_levels || 1
                ),
              }}
            >
              {currentAttempt.result}
            </b>
          )}
          <p className={styles.resultName}>
            {currentAttempt.result_details?.name}
          </p>
          {currentAttempt.result_details?.note && (
            <p className={styles.text}>{currentAttempt.result_details?.note}</p>
          )}
        </div>
      </div>
      <div className={styles.infoCard}>
        <div className={styles.info}>
          <div className={styles.header}>
            <LightBulb />
            <h3>Note</h3>
          </div>
          <p className={styles.text}>
            To get a description of this result, contact your doctor, who will
            tell you in detail what you need to do
          </p>
        </div>
        <img src={testImage} alt="test" className={styles.image} />
      </div>
      <div className={styles.buttons}>
        {Number(currentAttempt?.test.cost) === 0 && (
          <Button
            text="Take the test again"
            color="greenlight"
            onClick={takeTestAgain}
          />
        )}
        <Button text="Back to tests" onClick={() => navigate("./../..")} />
      </div>
    </div>
  );
};
