import { MouseEventHandler, useEffect, useRef } from "react";
import cn from "classnames";
import { useSnapshot } from "valtio";
import { Link } from "react-router-dom";
import { useResponsive } from "hooks/useResponsive";
import { useAuth } from "hooks/useAuth";
import { Patient } from "types/patient.types";
import { recommendationsService } from "services/recommendataionsService";
import { patientService } from "services/patientService";
import { blockEvent, getAvatar } from "common/utils";
import Button from "common/UIKit/Button";
import { recommendedPath } from "../../../../Recommended";
import { ReactComponent as Edit } from "assets/images/icons/edit.svg";
import { ReactComponent as Star } from "assets/images/icons/star-w-lights.svg";
import avatar from "assets/images/avatar.png";
import styles from "./ProfileCard.module.scss";

export const ProfileCard = () => {
  const { data } = useSnapshot(recommendationsService);
  const { isMobile } = useResponsive();
  const auth = useAuth();
  const user = auth.user as Patient;

  useEffect(() => {
    recommendationsService.list();
  }, []);

  const ref = useRef<HTMLInputElement>(null);
  const browseFile: MouseEventHandler<HTMLButtonElement> = (e) => {
    blockEvent(e);
    ref.current?.click();
  };
  const handleFileChange = async (files: FileList | null) => {
    files &&
      (await patientService.changeFile(user.id, files[0], user.file?.id));
  };
  const handleFileDelete = async (fileId?: number) =>
    fileId && (await patientService.deleteFile(user.id, fileId));

  return (
    <div className={cn({ [styles.card]: !isMobile }, styles.profileInfoCard)}>
      <div className={styles.profile}>
        <div className={cn(styles.avatar, user.file?.id && styles.withRemove)}>
          <img src={user?.file?.file || avatar} alt="avatar" />
          {user.file?.id && (
            <button
              className={styles.removeAvatar}
              type="button"
              onClick={() => handleFileDelete(user.file?.id)}
            >
              Remove photo
            </button>
          )}
          <Button className={styles.editIcon} onClick={browseFile}>
            <Edit />
          </Button>
          <input
            className={styles.hiddenInput}
            type="file"
            ref={ref}
            accept="image/jpg,image/gif,image/png,image/tiff,image/jpeg"
            onChange={(e) => handleFileChange(e.target.files)}
          />
        </div>
        <div className={styles.info}>
          <h2>
            {user.user.first_name} {user.user.last_name}
          </h2>
          <small>
            Joined{" "}
            {new Date(user.user.date_joined).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            })}
          </small>
        </div>
      </div>
      <div className={styles.recommended}>
        <Star />
        <div className={styles.text}>Recommended therapists:</div>
        <div className={styles.therapists}>
          {data.map(({ photos, id }) => (
            <img src={getAvatar(photos)?.file || avatar} alt="" key={id} />
          ))}
        </div>
        <Link to={`../..${recommendedPath}`} className={styles.link}>
          <span>See therapists</span>
        </Link>
      </div>
    </div>
  );
};
