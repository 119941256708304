import cn from "classnames";
import { useState } from "react";
import { NOTIFICATIONS } from "constants/fields";
import { format, parseISO } from "date-fns";
import { ReactComponent as ChevronBottomIcon } from "assets/images/icons/chevron-bottom.svg";
import { Notification } from "types/notifications.types";
import { notificationsService } from "services/notificationsService";
import styles from "../Notification.module.scss";

interface Props {
  notify: Notification;
}

export const NotificationItem = ({ notify }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const transaction = notify.notification?.transaction;

  const onOpen = () => {
    if (!isOpen && notify.id && !notify.is_viewed) {
      notificationsService.readById(String(notify.id));
    }
    setIsOpen((prevState) => !prevState);
  };

  return (
    <li
      className={cn(styles.item, !notify.is_viewed && styles.active)}
      onClick={onOpen}
    >
      <div className={styles.inner}>
        <p className={styles.itemTitle}>
          {NOTIFICATIONS[notify.notification.type]}
        </p>
        {isOpen ? (
          <>
            {notify.notification.type.includes("subscription_cancel") && (
              <p className={styles.itemText}>
                {`Your ${notify?.notification?.subscription.name} started on 
                ${format(
                  parseISO(notify.notification.subscription.start_at),
                  "MMMM d, yyyy"
                )} has been canceled`}
              </p>
            )}
            {transaction?.line_items &&
              Array.isArray(transaction?.line_items) &&
              transaction?.line_items?.length > 0 &&
              transaction?.line_items.map((item) => (
                <p
                  className={styles.itemText}
                >{`${item.lineItem.name} ($${item.lineItem.unitPrice})`}</p>
              ))}
            {transaction?.error_code && (
              <p className={styles.itemText}>
                {`Error ${transaction.error_code}: ${transaction.error}`}
              </p>
            )}
            <p className={styles.itemText}>
              {`${` ${format(
                parseISO(notify.created_at),
                "h:mma, d MMMM yyyy"
              )}`}`}
            </p>
          </>
        ) : (
          <p className={styles.itemText}>
            {`${` ${format(
              parseISO(notify.created_at),
              "h:mma, d MMMM yyyy"
            )}`}`}
          </p>
        )}
      </div>
      <ChevronBottomIcon
        className={styles.chevronIcon}
        style={{ transform: isOpen ? "rotate(180deg)" : "none" }}
      />
    </li>
  );
};
