import { DependencyList, useEffect, useMemo, useState } from "react";
import {
  SubmitHandler,
  useForm,
  UseFormProps,
  FieldValues,
  Path,
} from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AxiosResponse } from "axios";
import { useSnapshot } from "valtio";
import {
  FileDataInputs,
  makeDefaultValues,
  makeDiffToSend,
  TherapistFields,
} from "./util";
import { authService } from "services/authService";
import { filesService } from "services/filesService";
import { DeepPartial, goThroughErrors } from "common/utils";
import { ButtonProps } from "common/UIKit/Button/Button";
import { FileType, Therapist } from "types/therapist.types";
import { BaseUser } from "types/auth.types";
import { useResponsive } from "hooks/useResponsive";
import useFileUpload from "hooks/useFileUpload";
import useSkipSteps from "./useSkipSteps";

type Options<TInputs extends FieldValues> = {
  toFields?: (
    v: DeepPartial<Therapist> & DeepPartial<BaseUser>
  ) => DeepPartial<TInputs>;
  toApi?: (
    v: DeepPartial<TInputs>
  ) => Promise<DeepPartial<Therapist>> | DeepPartial<Therapist>;
  fileType?: FileType;
  mimeGroup?: string[];
  prevStep?: string;
  dependencies?: DependencyList;
  useFormProps?: UseFormProps<TInputs>;
};

function useAnyTherapistStep<TInputs extends FieldValues>(
  coveredFields: TherapistFields,
  nextStep: string,
  options: Options<TInputs> = {}
) {
  const {
    fileType,
    mimeGroup,
    prevStep,
    toApi,
    toFields,
    dependencies = [],
  } = options || {};
  const { isMobile } = useResponsive();
  const therapist = useSnapshot(authService).user as Therapist | null;
  const skipSteps = useSkipSteps();

  useEffect(() => {
    therapist && filesService.list(therapist.id);
  }, [therapist]);

  // files
  const { fileUploadProps, handleSubmit: handleSubmitFiles } = useFileUpload(
    fileType || "photo_and_video_intro",
    mimeGroup || []
  );

  const withFiles = !!(fileType && mimeGroup);
  const { data: files } = useSnapshot(filesService);
  const suitableFiles = useMemo(
    () =>
      withFiles
        ? files.filter(
            (f) =>
              mimeGroup.map((mime) => f.mime.startsWith(mime)) &&
              f.type === fileType
          )
        : [],
    [files, fileType, mimeGroup, withFiles]
  );

  const navigate = useNavigate();

  type ForFiles = typeof fileType extends FileType ? FileDataInputs : {};
  type Inputs = TInputs & ForFiles;

  const defaultValues: any = useMemo(() => {
    if (!therapist) {
      return {};
    }
    const df = makeDefaultValues(therapist, coveredFields);
    if (withFiles) {
      (df as FileDataInputs).fileData = {
        currentValue: suitableFiles,
        toDelete: [],
      };
    }
    return toFields?.(df) || df;
    //eslint-disable-next-line
  }, [...dependencies, therapist]);

  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors },
    setValue,
    getValues,
    reset,
    watch,
  } = useForm<Inputs>({ ...options?.useFormProps, defaultValues });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const submitHandler: SubmitHandler<Inputs> = async (values) => {
    await setIsSubmitting(true);
    const diff = toApi
      ? await toApi(values as any)
      : makeDiffToSend(values, coveredFields);

    try {
      await authService.patch({ diff });
      if (withFiles) await handleSubmitFiles();

      navigate(nextStep);
    } catch (e) {
      const { data, status } = e as AxiosResponse;

      if (status === 400) {
        goThroughErrors(data, (fieldName, errorMessage) =>
          setError(fieldName as Path<Inputs>, {
            message: errorMessage,
            type: "custom",
          })
        );
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const buttons: ButtonProps[] = [
    {
      type: "submit",
      children: isMobile ? "Continue" : "Next",
      isLoading: isSubmitting,
    },
  ];
  if (prevStep) {
    buttons.unshift({
      type: "button",
      color: "greenlight",
      children: "Back",
      className: "btn-color-blue",
      isLink: true,
      to: prevStep,
      disabled: isSubmitting,
    });
  }

  const skipHandler: SubmitHandler<Inputs> = async (values) => {
    const diff = toApi
      ? await toApi(values as any)
      : makeDiffToSend(values, coveredFields);

    try {
      if (withFiles) await handleSubmitFiles();
      await skipSteps(diff);
    } catch (e) {
      const { data, status } = e as AxiosResponse;
      if (status === 400) {
        goThroughErrors(data, (fieldName, errorMessage) =>
          setError(fieldName as Path<Inputs>, {
            message: errorMessage,
            type: "custom",
          })
        );
      }
    }
  };

  return {
    register,
    handleSubmit: handleSubmit(submitHandler),
    control,
    errors,
    isSubmitting,
    setValue,
    setError,
    getValues,
    watch,
    fileUploadProps,
    defaultValues,
    actionsProps: {
      buttons,
      onSkip: handleSubmit(skipHandler),
    },
  };
}

export default useAnyTherapistStep;
