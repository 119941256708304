import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";

import Button from "common/UIKit/Button";
import { Block } from "../components/Block";
import { testProcessService } from "services/testProcessService";
import { useTest } from "hooks/useTest";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { EditHeader } from "common/components/EditHeader";
import { testsResultPath } from "../TestResult";
import { ErrorMessage } from "common/UIKit/ErrorMessage";
import styles from "./TestStart.module.scss";

export const testsStartPath = "/start";

export const TestStart = () => {
  const { test } = useSnapshot(testProcessService);
  const navigate = useNavigate();
  useDashboardNavigation(test?.name || "Unknown Test", {
    isNavigationHidden: true,
    goBack: () => navigate("./../.."),
  });
  const { isSubmitting, getSubmitFunction, errors } = useTest();

  const nonIntroBlocks = test?.blocks.filter((block) => !block.is_intro);

  const handleSubmit = getSubmitFunction("finished", () =>
    navigate(`..${testsResultPath}`)
  );

  return (
    <div className={styles.testsPrepare}>
      <form onSubmit={handleSubmit}>
        <EditHeader withoutBack />
        {errors.global && <ErrorMessage>{errors.global.message}</ErrorMessage>}
        <div>
          {nonIntroBlocks?.map((block) => (
            <Block block={block} key={block.id} />
          ))}
        </div>
        <Button
          className={styles.btn}
          type="submit"
          text="Finish"
          isLoading={isSubmitting}
        />
      </form>
    </div>
  );
};
