import useFetch from "hooks/useFetch";
import { filesService } from "services/filesService";
import { FormEvent, useEffect, useState } from "react";
import { FileType, TherapistFile } from "types/therapist.types";
import { useAuth } from "./useAuth";
import { useLocation } from "react-router-dom";
import { useQuery } from "./useQuery";

type FileValue = {
  id?: number;
  src: string;
  type: string;
  file?: File;
  isMain?: boolean;
};

type Options = {
  onSuccess?: (createdFiles: TherapistFile[]) => any | Promise<any>;
  onSkip?: (createdFiles: TherapistFile[]) => any | Promise<any>;
  instantRemove?: boolean;
  instantUpload?: boolean;
};

const filesToValues = (files: TherapistFile[]) =>
  files.map((item) => ({
    id: item.id,
    src: item.file,
    type: item.mime.split("/")[0],
    isMain: item.is_main_photo,
  }));

function useFileUpload(
  fileType: FileType,
  mimeGroup: string[],
  options: Options = {},
  anotherData?: TherapistFile[]
) {
  const { user } = useAuth();
  const q = useQuery();
  const therapistId = q.get("therapistId");
  const { onSuccess, onSkip, instantRemove, instantUpload } = options;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [values, setValues] = useState<FileValue[]>([]);
  const [deleteList, setDeleteList] = useState<number[]>([]);
  const [initialData, setInitialData] = useState<TherapistFile[]>([]);
  const userId = user?.id || therapistId;

  const fetchFiles = useFetch<TherapistFile[]>(
    async () => {
      if (!userId) {
        return [];
      }
      return (
        !anotherData &&
        !Array.isArray(anotherData) &&
        (await filesService.list(userId, fileType, false))
      );
    }
    // {
    //   transformer: (v) =>
    //     v.filter((f: TherapistFile) =>
    //       mimeGroup.some((mime) => f.mime.startsWith(mime))
    //     ),
    // }
  );

  useEffect(() => {
    if (!Array.isArray(anotherData)) {
      setInitialData(fetchFiles.data);
    } else {
      if (anotherData.length > 0) {
        setInitialData(anotherData);
      }
    }
  }, [fetchFiles.data, anotherData]);

  useEffect(() => {
    setValues(filesToValues(initialData));
  }, [initialData]);

  const onAddFiles = async (files: File[]) => {
    if (!userId) {
      return;
    }

    const newValuesBeforeUploading = files.map((file) => {
      const { type } = file;
      const dataUrl = URL.createObjectURL(file);
      const srcType = type.split("/")[0];

      return { src: dataUrl, type: srcType, file };
    });

    const valuesBeforeUploading = values;
    setValues((values) => [...values, ...newValuesBeforeUploading]);

    if (instantUpload) {
      await setIsSubmitting(true);
      const createdFiles = await filesService.createFiles(
        userId,
        newValuesBeforeUploading.map((v) => v.file) as File[],
        fileType
      );
      const newValuesAfterUploading = filesToValues(createdFiles);
      await setValues(valuesBeforeUploading.concat(newValuesAfterUploading));
      setIsSubmitting(false);
      onSuccess && (await onSuccess(createdFiles));
    }
  };

  const onRemoveFile = (index: number) => {
    if (!userId) {
      return;
    }
    const { id, type } = values[index];

    if (id && instantRemove) filesService.deleteFile(userId, id, type);
    if (id && !instantRemove) setDeleteList([...deleteList, id]);

    setValues([...values.slice(0, index), ...values.slice(index + 1)]);
  };

  const onRemoveFileById = (id: number) => {
    const index = values.findIndex((file) => file.id === id);
    if (index >= 0) onRemoveFile(index);
  };

  const handleSubmit = async (event?: FormEvent, license?: string) => {
    event && event.preventDefault();
    if (!userId) {
      return;
    }
    try {
      await setIsSubmitting(true);
      await Promise.all(
        deleteList.map((id) => filesService.deleteFile(userId, id))
      );

      const createdFiles = instantUpload
        ? []
        : await filesService.createFiles(
            userId,
            values.filter((v) => v.file).map((v) => v.file) as File[],
            fileType,
            license
          );

      onSuccess && (await onSuccess(createdFiles));
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSkip = async () => {
    if (!userId) {
      return;
    }
    try {
      await setIsSubmitting(true);
      await Promise.all(
        deleteList.map((id) => filesService.deleteFile(userId, id))
      );

      const createdFiles = instantUpload
        ? []
        : await filesService.createFiles(
            userId,
            values.filter((v) => v.file).map((v) => v.file) as File[],
            fileType
          );

      onSkip && (await onSkip(createdFiles));
      setIsSubmitting(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onChangeIsMain = async (id?: number) => {
    if (!userId) {
      return;
    }
    const newData = [...values];
    for (const obj of newData) {
      obj.isMain = obj.id === id;
    }

    try {
      id && (await filesService.makeAsMainPhoto(userId, id));
      id && setValues(newData);
    } catch (error) {
      console.error(error);
    }
  };

  const onAbortUploading = () => {
    filesService.abortUpload && filesService.abortUpload();
    setIsSubmitting(false);
    setValues([]);
  };

  return {
    handleSubmit,
    handleSkip,
    isSubmitting,
    fileUploadProps: {
      value: values,
      onChangeIsMain,
      onAddFiles,
      onRemoveFile,
      onRemoveFileById,
      onAbortUploading,
      initialValue: initialData.map((f) => ({
        src: f.file,
        type: f.mime.split("/")[0],
        id: f.id,
      })),
      disabled: fetchFiles.isFetching || isSubmitting,
      name: "fileData",
    },
  };
}

export default useFileUpload;
