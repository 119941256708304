import React, { HTMLProps } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import "./Button.scss";

export interface ButtonProps
  extends Omit<HTMLProps<HTMLButtonElement>, "size"> {
  color?:
    | "green"
    | "greenlight"
    | "white"
    | "red"
    | "yellow"
    | "turquoise"
    | "none";
  size?: "full" | "small" | "wide" | "auto";
  text?: string;
  type?: "button" | "submit" | "reset";
  isLink?: boolean;
  to?: string | void;
  href?: string;
  target?: "_blank";
  disabled?: boolean;
  isLoading?: boolean;
  rel?: string;
  state?: any;
}

function Button({
  color = "green",
  onClick,
  size = "auto",
  text,
  children,
  type = "button",
  className,
  isLink,
  to = "/",
  href,
  target,
  disabled,
  isLoading,
  rel,
  state,
  ...rest
}: ButtonProps) {
  const classNames = cn(
    "btn",
    `btn-color-${color}`,
    `btn-size-${size}`,
    className
  );

  if (isLink) {
    return href ? (
      <a href={href} target={target} className={classNames} rel={rel}>
        {text || children}
      </a>
    ) : (
      <Link
        to={to}
        state={state}
        className={classNames}
        target={target}
        rel={rel}
      >
        {text || children}
      </Link>
    );
  }

  return (
    <button
      type={type}
      onClick={onClick}
      className={classNames}
      disabled={disabled || isLoading}
      {...rest}
    >
      {text || children}
      {isLoading && <div className="btn__spinner" />}
    </button>
  );
}

export default Button;
