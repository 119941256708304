import { stepsUi } from "../components";
import { PrimaryCredentialType } from "types/therapist.types";
import { CredentialType } from "types/credential.types";
import AddButton from "common/UIKit/AddButton";
import Card from "common/UIKit/Card";
import { ReactComponent as DocumentIcon } from "assets/images/icons/document.svg";
import { ReactComponent as EditIcon } from "assets/images/icons/pencil.svg";
import { useAuth } from "hooks/useAuth";
import { useSnapshot } from "valtio";
import { stateLicenseService } from "services/stateLicenseService";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ButtonProps } from "common/UIKit/Button/Button";
import { useResponsive } from "hooks/useResponsive";
import useSkipSteps from "../useSkipSteps";
import { step2FullPath } from "../TherapistStep2";
import { step3_2FullPath } from "../TherapistStep3_2";
import { FormatProfileField } from "common/components/FormatProfileField";
import {
  NO_LICENSE_LABEL,
  NO_LICENSE_ORGANIZATION_LABEL,
  NO_LICENSE_ORGANIZATION_NUMBER,
  NUMBER_FIELD,
  ORGANIZATION_FIELD,
  STATE_LICENSE_LABEL,
} from "constants/fields";
import { NO_LICENSE_YEAR } from "constants/fields";
import styles from "./TherapistStep3_1.module.scss";

export type Inputs = {
  state_license_type: PrimaryCredentialType;
  license_number: string;
  license_state: string;
  license_expiration: string | null;
  mental_health_role: string;
  supervisor_name: string;
  supervisor_license_number: string;
  supervisor_license_expiration: string | null;
  supervisor_license_state: string;
  verified_credential_type: string;
  state_license_is_verified: boolean;
  no_license_type?: CredentialType;
  no_license_year: number | null;
  no_license_membership_organization: string;
  no_license_membership_id: string;
  no_license_license_issuer: string;
  no_license_license_number: string;
  no_license_accrediting_institution: string;
  no_license_certificate_number: string;
  no_license_diploma_school_graduated: string;
  no_license_diploma_degree_type: string;
};

export function TherapistStep3_1() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { data } = useSnapshot(stateLicenseService);
  const { isMobile } = useResponsive();
  const skipSteps = useSkipSteps();

  const buttons: ButtonProps[] = [
    {
      type: "button",
      color: "greenlight",
      children: "Back",
      className: "btn-color-blue",
      isLink: true,
      to: step2FullPath,
    },
    {
      type: "button",
      children: isMobile ? "Continue" : "Next",
      to: step3_2FullPath,
      isLink: true,
    },
  ];

  useEffect(() => {
    user && stateLicenseService.list(user.id, undefined, false);
  }, [user]);

  return (
    <>
      <stepsUi.Header>Credentials</stepsUi.Header>
      <stepsUi.Hint className={`therapist-step-3__hint ${styles.hint}`}>
        State Licensing 1/3
      </stepsUi.Hint>
      <stepsUi.Form
        actions={<stepsUi.Actions buttons={buttons} onSkip={skipSteps} />}
        handleSubmit={() => {}}
        className={styles.root}
      >
        {data.length && user
          ? data.map((credential) => (
              <Card
                key={credential.id}
                className={styles.item}
                values={[
                  {
                    caption: "CREDENTIAL",
                    text: (
                      <FormatProfileField maxLength={30}>
                        {credential.no_license_type &&
                        credential.state_license_type === "i_have_no_license"
                          ? NO_LICENSE_LABEL[credential.no_license_type]
                          : STATE_LICENSE_LABEL[credential.state_license_type]}
                      </FormatProfileField>
                    ),
                  },
                  ...(credential.no_license_type &&
                  credential.state_license_type === "i_have_no_license"
                    ? [
                        {
                          caption:
                            NO_LICENSE_ORGANIZATION_LABEL[
                              credential.no_license_type
                            ],
                          text: (
                            <FormatProfileField>
                              {
                                credential[
                                  `no_license_${
                                    ORGANIZATION_FIELD[
                                      credential.no_license_type
                                    ]
                                  }`
                                ]
                              }
                            </FormatProfileField>
                          ),
                        },
                        {
                          caption:
                            NO_LICENSE_ORGANIZATION_NUMBER[
                              credential.no_license_type
                            ],
                          text: (
                            <FormatProfileField>
                              {
                                credential[
                                  `no_license_${
                                    NUMBER_FIELD[credential.no_license_type]
                                  }`
                                ]
                              }
                            </FormatProfileField>
                          ),
                        },
                        {
                          caption: NO_LICENSE_YEAR[credential.no_license_type],
                          text: (
                            <FormatProfileField>
                              {credential.no_license_year}
                            </FormatProfileField>
                          ),
                        },
                      ]
                    : []),
                  ...(credential.state_license_type === "i_am_pre_licensed"
                    ? [
                        {
                          caption: "SUPERVISOR’S LICENSE",
                          text: (
                            <FormatProfileField>
                              {credential.supervisor_license_number}
                            </FormatProfileField>
                          ),
                        },
                        {
                          caption: "SUPERVISOR’S STATE",
                          text: (
                            <FormatProfileField>
                              {credential.supervisor_license_state}
                            </FormatProfileField>
                          ),
                        },
                        {
                          caption: "EXPIRATION",
                          text: (
                            <FormatProfileField>
                              {credential.supervisor_license_expiration}
                            </FormatProfileField>
                          ),
                        },
                      ]
                    : []),
                  ...(credential.state_license_type === "i_am_licensed"
                    ? [
                        {
                          caption: "LICENSE NUMBER",
                          text: (
                            <FormatProfileField>
                              {credential.license_number}
                            </FormatProfileField>
                          ),
                        },
                        {
                          caption: "LICENSE STATE",
                          text: (
                            <FormatProfileField>
                              {credential.license_state}
                            </FormatProfileField>
                          ),
                        },
                        {
                          caption: "EXPIRATION",
                          text: (
                            <FormatProfileField>
                              {credential.license_expiration}
                            </FormatProfileField>
                          ),
                        },
                      ]
                    : []),
                ]}
                icon={<DocumentIcon />}
                onEdit={() => navigate(`add/${String(credential.id)}`)}
                onRemove={() =>
                  stateLicenseService.delete(user.id, credential.id)
                }
                editIcon={<EditIcon />}
                buttonClass={styles.cardButton}
              />
            ))
          : null}
        <AddButton className={styles.addButton} onClick={() => navigate("add")}>
          Add a state licensing
        </AddButton>
      </stepsUi.Form>
    </>
  );
}
