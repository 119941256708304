import { Modal } from "common/components/Modal";
import { ReactComponent as CloseIcon } from "assets/images/icons/close-square.svg";
import { useModal } from "hooks/useModal";
import { RateForm } from "../blocks/RateForm/RateForm";
import Button from "common/UIKit/Button";
import {
  Control,
  UseFormClearErrors,
  UseFormSetError,
  UseFormWatch,
} from "react-hook-form";
import { RateCard } from "types/rateCards.types";
import { useEffect } from "react";
import styles from "./RateDetails.module.scss";

interface Props {
  control: Control<RateCard>;
  type?: string;
  watch: UseFormWatch<RateCard>;
  sessions: number;
  free: number;
  totalPay: number;
  fees: string;
  setError: UseFormSetError<RateCard>;
  clearErrors: UseFormClearErrors<RateCard>;
}

interface BlockProps {
  type?: string;
  control: Control<RateCard>;
  watch: UseFormWatch<RateCard>;
  sessions: number;
  free: number;
  totalPay: number;
  fees: string;
}

const RateBlock = ({
  type,
  control,
  sessions,
  free,
  totalPay,
  fees,
}: BlockProps) => {
  switch (type) {
    case "special":
      return (
        <>
          <RateForm
            control={control}
            title="Sessions and fees"
            inputs={[
              {
                label: "# Sessions patient pays for:",
                name: "pay_sessions_count",
              },
              {
                label: "Fee for each paid session:",
                name: "fee",
                adornment: "$",
                price: true,
              },
              {
                label: "# Sessions patient receives free:",
                name: "get_free_count",
              },
            ]}
          />
          <div className={styles.info}>
            <p
              className={styles.infoItem}
            >{`Buy ${sessions} Get ${free} Free`}</p>
            <p
              className={styles.infoItem}
            >{`Total charged for each session: $${Number(fees).toFixed(2)}`}</p>
            <p
              className={styles.infoItem}
            >{`Total to be charged: $${totalPay.toFixed(2)}`}</p>
          </div>
        </>
      );
    case "hourly":
      return (
        <>
          <RateForm
            control={control}
            title="Number of hours"
            inputs={[
              {
                label: "Enter the number of hours:",
                name: "pay_sessions_count",
              },
            ]}
          />
          <RateForm
            control={control}
            title="Fees"
            inputs={[
              {
                label: "Total fee for this session:",
                name: "fee",
                adornment: "$",
                price: true,
              },
            ]}
          />
        </>
      );
    case "weekly":
      return (
        <>
          <RateForm
            control={control}
            title="Number of hours"
            inputs={[
              {
                label: "Enter the number of hours:",
                name: "pay_sessions_count",
              },
            ]}
          />
          <RateForm
            control={control}
            title="Fees"
            inputs={[
              {
                label: "Total fee to be charged:",
                name: "fee",
                adornment: "$",
                price: true,
              },
            ]}
          />
        </>
      );
    case "monthly":
      return (
        <>
          <RateForm
            control={control}
            title="Number of hours"
            inputs={[
              {
                label: "Enter the number of hours:",
                name: "pay_sessions_count",
              },
            ]}
          />
          <RateForm
            control={control}
            title="Fees"
            inputs={[
              {
                label: "Total fee to be charged:",
                name: "fee",
                adornment: "$",
                price: true,
              },
            ]}
          />
        </>
      );
    default:
      return null;
  }
};

export const RateDetails = ({
  control,
  type,
  watch,
  free,
  sessions,
  totalPay,
  fees,
  setError,
  clearErrors,
}: Props) => {
  const { isOpen, close, closeAll } = useModal("RATE_CARD_DETAILS");
  const { open: openRateModal } = useModal("RATE_CARD_TYPE");
  const { open: openDescriptionModal } = useModal("RATE_CARD_DESCRIPTION");
  const paySessionsCount = watch("pay_sessions_count");
  const freeSessionsCount = watch("get_free_count");

  const onBackClick = () => {
    openRateModal();
    close();
  };

  useEffect(() => {
    if (fees) {
      clearErrors("fee");
    }
  }, [fees, clearErrors]);

  const validateFields = () => {
    let isValid = true;
    if (!Number(fees)) {
      setError("fee", { message: "This field is required." });
      isValid = false;
    }

    if (!Number(paySessionsCount)) {
      setError("pay_sessions_count", {
        message: "This field is required.",
      });
      isValid = false;
    }

    if (type === "special" && !Number(freeSessionsCount)) {
      setError("get_free_count", {
        message: "This field is required.",
      });
      isValid = false;
    }

    return isValid;
  };

  const onNextClick = () => {
    if (!validateFields()) return;

    openDescriptionModal();
    close();
  };

  return (
    <Modal className={styles.wrapper} visible={isOpen} onClose={closeAll}>
      <div className={styles.root}>
        <div className={styles.inner}>
          <button className={styles.closeButton} onClick={closeAll}>
            <CloseIcon />
          </button>
          <h1 className={styles.title}>Add a {type} rate card</h1>
          <p className={styles.subtitle}>Step 2 - Enter the details:</p>
          <div className={styles.form}>
            <RateBlock
              control={control}
              type={type}
              watch={watch}
              free={free}
              sessions={sessions}
              totalPay={totalPay}
              fees={fees}
            />
          </div>
          <div className={styles.rowButtons}>
            <Button
              className={styles.button}
              color="greenlight"
              onClick={onBackClick}
            >
              Back
            </Button>
            <Button className={styles.button} onClick={onNextClick}>
              Next
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
