import { FC, ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import { parseISO, format } from "date-fns";
import Button from "common/UIKit/Button";
import Radiobutton from "common/UIKit/Radiobutton";
import { PaymentPeriod, Plan } from "types/plan.types";
import { Therapist } from "types/therapist.types";
import { useResponsive } from "hooks/useResponsive";
import { useAuth } from "hooks/useAuth";
import { useModal } from "hooks/useModal";
import { ReactComponent as VerifiedIcon } from "assets/images/icons/verified.svg";
import { ReactComponent as StarIcon } from "assets/images/icons/plan-star.svg";
import { ReactComponent as CrownIcon } from "assets/images/icons/plan-crown.svg";
import { ReactComponent as CrossIcon } from "assets/images/icons/cross.svg";
import { ReactComponent as DecorationRight } from "assets/images/premium-decoration-right.svg";
import { ReactComponent as DecorationBottom } from "assets/images/premium-decoration-bottom.svg";
import { paymentPath } from "../../Payment";
import { features } from "./constants";
import styles from "./PlanCard.module.scss";

interface Decoration {
  top?: ReactElement;
  right?: ReactElement;
  bottom?: ReactElement;
  left?: ReactElement;
}

const decorations: Decoration[] = [
  {},
  {
    right: <DecorationRight />,
    bottom: <DecorationBottom />,
  },
];

interface Props {
  plan: Plan;
  period: PaymentPeriod;
}

export const PlanCard: FC<Props> = ({ plan, period }) => {
  const navigate = useNavigate();
  const user = useAuth().user as Therapist;
  const { isMobile } = useResponsive();
  const { open } = useModal("CANCEL_SUBSCRIPTION_PLAN");

  const planForPeriod = user
    ? period === "month"
      ? user?.current_subscription.monthly
      : user?.current_subscription.yearly
    : null;

  const isSelected = user
    ? (!user?.current_subscription.monthly &&
        !user?.current_subscription.yearly &&
        user.subscription_plan.id === plan.id) ||
      planForPeriod?.plan === plan.id
    : null;

  const planFeatures = features.length > 0 ? features[plan.id - 1] : null;
  const planDecorations =
    decorations.length > 0 ? decorations[plan.id - 1] : null;

  const goToPayment = () =>
    navigate({
      pathname: `.${paymentPath}`,
      search: `?planId=${plan.id}&period=${period}`,
    });
  const handleClick = () => {
    if (isSelected) {
      return;
    }

    if (user) {
      if (plan.cost === "0.00" && !isSelected) {
        open();
      } else {
        goToPayment();
      }
    } else {
      navigate("/therapist/login");
    }
  };

  return (
    <div
      className={cn(styles.planCard, isSelected && styles.selected)}
      onClick={isMobile ? handleClick : undefined}
      role={isMobile ? "button" : undefined}
    >
      {!isMobile && plan.name === "Premium" && (
        <p className={styles.bestOffer}>Best offer</p>
      )}
      <div className={styles.topPart}>
        <div className={styles.planIcons}>
          {plan.name === "Free" ? (
            <span>$0</span>
          ) : plan.name === "Preferred" ? (
            <StarIcon />
          ) : (
            <CrownIcon />
          )}
        </div>
        <div className={styles.name}>
          <h1>{plan.name}</h1>
        </div>
      </div>
      <div className={styles.features}>
        {planFeatures?.map((feature) => (
          <div className={styles.feature} key={feature.name}>
            {feature.value ? <VerifiedIcon /> : <CrossIcon />}
            <p className={cn(!feature.value && styles.disabled)}>
              {feature.name}
              {feature.name.includes("Chat Connect") && <span>&trade;</span>}
            </p>
          </div>
        ))}
      </div>
      {Number(plan.cost) > 0 && (
        <div className={styles.priceContainer}>
          <div>
            <p className={styles.price}>
              $
              {period === "month"
                ? Math.floor(Number(plan.cost))
                : Math.floor(Number(plan.cost_yearly))}
            </p>
          </div>
          <div>{period}</div>
          {isMobile && plan.name === "Premium" && (
            <p className={styles.bestOffer}>Best offer</p>
          )}
        </div>
      )}
      {isMobile ? (
        <Radiobutton
          checked={user && isSelected ? isSelected : false}
          className={cn(styles.radio, isSelected && styles.selected)}
        />
      ) : (
        <>
          <Button
            size="full"
            text={isSelected ? "Current Plan" : "Select"}
            className={cn(isSelected && styles.selected)}
            onClick={handleClick}
          />
          {planForPeriod?.cancelled_at && planForPeriod.plan === plan.id && (
            <span className={styles.cancellationDate}>
              Until{" "}
              {format(parseISO(planForPeriod.cancelled_at), "MMMM d, yyyy")}
            </span>
          )}
        </>
      )}
    </div>
  );
};
