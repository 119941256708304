import cn from "classnames";
import { FC } from "react";
import { TestQuestion, TestQuestionType } from "types/testProcess.types";
import { Radio } from "../../inputs/Radio";
import { Multi } from "../../inputs/Multi";
import { Dropdown } from "../../inputs/Dropdown";
import { TextBox } from "../../inputs/TextBox";
import { TextArea } from "../../inputs/TextArea";
import { Numeric } from "../../inputs/Numeric";
import styles from "./Question.module.scss";

const map: Record<TestQuestionType, FC<any>> = {
  radio: Radio,
  multi: Multi,
  dropdown: Dropdown,
  text_box: TextBox,
  text_area: TextArea,
  numeric: Numeric,
};

interface Props {
  question: TestQuestion;
  isIntro?: boolean;
  disabled?: boolean;
  placeholder?: string;
}

export const Question: FC<Props> = ({
  question,
  isIntro = false,
  disabled,
  placeholder,
}) => {
  const Component = map[question.type];

  return (
    <div
      className={cn(
        styles.question,
        isIntro && styles.intro,
        !isIntro && disabled && styles.disabled
      )}
    >
      <h3>
        {!isIntro && `${question.order}) `}
        {question.question}
      </h3>
      <Component
        question={question}
        disabled={disabled}
        placeholder={placeholder}
      />
    </div>
  );
};
