import { Modal } from "common/components/Modal";
import Button from "common/UIKit/Button";
import { useModal } from "hooks/useModal";
import { ReactComponent as CloseIcon } from "assets/images/icons/close-square.svg";
import { toast } from "react-toastify";
import { authService } from "services/authService";
import { connectStripeService } from "services/stripeConnectService";
import { Therapist } from "types/therapist.types";
import { useAuth } from "hooks/useAuth";
import styles from "./StripeCancellation.module.scss";

export const StripeCancellation = () => {
  const { isOpen, close, closeAll } = useModal("DISCONNECT_STRIPE");
  const user = useAuth().user as Therapist;

  const handleConfirm = async () => {
    try {
      await connectStripeService.disconnect(user.id);
      await authService.getCurrentUser();
      closeAll();
    } catch (error) {
      toast("Error", { type: "error" });
    }
  };

  return (
    <Modal className={styles.wrapper} visible={isOpen} onClose={close}>
      <div className={styles.modal}>
        <button className={styles.closeButton} onClick={close}>
          <CloseIcon />
        </button>
        <h1>Are you sure you want to disconnect Stripe?</h1>
        <div className={styles.buttons}>
          <Button text="Confirm" color="greenlight" onClick={handleConfirm} />
          <Button text="Cancel" onClick={close} />
        </div>
      </div>
    </Modal>
  );
};
