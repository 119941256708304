import { stepsUi } from "../components/index";
import { educationService } from "services/educationService";
import { goThroughErrors } from "common/utils";
import { step3_2FullPath } from "../TherapistStep3_2";
import { Controller, SubmitHandler, useForm, Path } from "react-hook-form";
import { AxiosResponse } from "axios";
import { ChangeEvent, useEffect, useState } from "react";
import { Education } from "types/education.types";
import { useAuth } from "hooks/useAuth";
import { useNavigate, useParams } from "react-router-dom";
import Input from "common/UIKit/Input";
import { ButtonProps } from "common/UIKit/Button/Button";
import styles from "./TherapistAddEducation.module.scss";

type Inputs = {
  year_graduated: number | null;
  degree_diploma: string;
  school_graduated: string;
};

const defaultValues: Inputs = {
  year_graduated: null,
  degree_diploma: "",
  school_graduated: "",
};

export const TherapistAddEducation = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [education, setEducation] = useState<Education | null>(null);
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    control,
    reset,
    setError,
  } = useForm<Inputs>({ defaultValues });

  useEffect(() => {
    if (id && user) {
      (async () => {
        await educationService
          .getById(user.id, Number(id))
          .then(setEducation)
          .catch((err) => {
            console.log(err);
          });
      })();
    }
  }, [id, user]);

  useEffect(() => {
    if (id && education) {
      reset({
        ...(education.degree_diploma && {
          degree_diploma: education.degree_diploma,
        }),
        ...(education.school_graduated && {
          school_graduated: education.school_graduated,
        }),
        ...(education.year_graduated && {
          year_graduated: education.year_graduated,
        }),
      });
    }
  }, [education, id, reset]);

  const submitHandler: SubmitHandler<Inputs> = async (data) => {
    if (!user) return;

    try {
      education?.id
        ? await educationService.patch(user.id, education.id, data)
        : await educationService.create(user.id, data);
      navigate(step3_2FullPath);
    } catch (error) {
      const { data, status } = error as AxiosResponse;
      if (status === 400) {
        goThroughErrors(data, (fieldName, errorMessage) =>
          setError(fieldName as Path<Inputs>, {
            message: errorMessage,
            type: "custom",
          })
        );
      }
    }
  };

  const buttons: ButtonProps[] = [
    {
      type: "button",
      color: "greenlight",
      children: "Back",
      className: "btn-color-blue",
      isLink: true,
      to: step3_2FullPath,
      disabled: isSubmitting,
    },
    {
      type: "submit",
      children: "Save",
      isLoading: isSubmitting,
    },
  ];

  return (
    <>
      <stepsUi.Header>Credentials</stepsUi.Header>
      <stepsUi.Hint className="therapist-step-3__hint">
        EDUCATION 2/3
      </stepsUi.Hint>
      <stepsUi.Form
        actions={<stepsUi.Actions buttons={buttons} />}
        handleSubmit={handleSubmit(submitHandler)}
        className={styles.root}
      >
        <stepsUi.Grid>
          <stepsUi.Row label="School graduated" className={styles.row}>
            <Input
              type="text"
              placeholder=""
              {...register("school_graduated")}
              error={errors.school_graduated?.message}
            />
          </stepsUi.Row>
          <stepsUi.Row label="Degree / Diploma" className={styles.row}>
            <Input
              type="text"
              placeholder=""
              {...register("degree_diploma")}
              error={errors.degree_diploma?.message}
            />
          </stepsUi.Row>
        </stepsUi.Grid>
        <stepsUi.Row label="Year graduated" className={styles.lastRow}>
          <Controller
            name="year_graduated"
            control={control}
            rules={{
              validate: {
                nonNegative: (value) =>
                  value === null ||
                  (value && value >= 0) ||
                  "Values must be non-negative",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <Input
                {...field}
                placeholder="YYYY"
                type="text"
                error={errors.year_graduated?.message}
                maxLength={4}
                isValid={!error}
                value={field?.value ? field.value : ""}
                name={field.name}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const inputValue = e.target.value;

                  if (!isNaN(+inputValue)) {
                    field.value = inputValue === "" ? 0 : +inputValue;
                    field.onChange(field.value);
                  }
                }}
              />
            )}
          />
        </stepsUi.Row>
      </stepsUi.Form>
    </>
  );
};
