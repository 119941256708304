import { FC } from "react";

import { useResponsive } from "hooks/useResponsive";
import { MyAccountLayout } from "../MyAccountLayout";
import { PersonalInformationDesktop } from "./PersonalInformationDesktop";
import { PersonalInformationMobile } from "./PersonalInformationMobile";

export const personalInformationPath = "/personal-information";

interface Props {
  onEdit: () => void;
  onChangePassword: () => void;
}

export const PersonalInformation: FC<Props> = ({
  onEdit,
  onChangePassword,
}) => {
  const { isMobile } = useResponsive();

  return (
    <MyAccountLayout>
      {isMobile ? (
        <PersonalInformationMobile />
      ) : (
        <PersonalInformationDesktop
          onEdit={onEdit}
          onChangePassword={onChangePassword}
        />
      )}
    </MyAccountLayout>
  );
};
