import { useSnapshot } from "valtio";
import { useNavigate } from "react-router-dom";
import Button from "common/UIKit/Button";
import { testProcessService } from "services/testProcessService";
import { Block } from "../components/Block";
import { testsStartPath } from "../TestStart";
import { EditHeader } from "common/components/EditHeader";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { useTest } from "hooks/useTest";
import { ErrorMessage } from "common/UIKit/ErrorMessage";
import styles from "./TestPrepare.module.scss";

export const testsPreparePath = "/prepare";

export const TestPrepare = () => {
  const { test } = useSnapshot(testProcessService);
  const navigate = useNavigate();
  useDashboardNavigation(test?.name || "Unknown Test", {
    isNavigationHidden: true,
    goBack: () => navigate("./../.."),
  });
  const { getSubmitFunction, isSubmitting, errors } = useTest();

  const introBlocks = test?.blocks.filter((block) => block.is_intro);

  const handleContinue = getSubmitFunction("progress", (attemptId: number) =>
    navigate(`../../${attemptId}${testsStartPath}`)
  );

  return (
    <div className={styles.testPrepare}>
      <form onSubmit={handleContinue}>
        <EditHeader withoutBack />
        {errors.global && <ErrorMessage>{errors.global.message}</ErrorMessage>}
        {introBlocks?.map((block) => (
          <Block block={block} key={block.id} />
        ))}
        <div className={styles.instructions}>
          <div className={styles.wrapper}>
            <h3 className={styles.instructionsTitle}>Instructions</h3>
            <p className={styles.instructionsText}>{test?.description}</p>
          </div>
          <Button
            type="submit"
            className={styles.btn}
            text="Start testing"
            isLoading={isSubmitting}
          />
        </div>
      </form>
    </div>
  );
};
