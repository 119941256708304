import styles from "./PhotosAndVideos.module.scss";
import VideoPlayer from "common/components/Video/Player";
import ControlledFileUpload from "common/components/ControlledFileUpload";
import { BrowseButton } from "common/components/FileUpload";
import { useSnapshot } from "valtio";
import { filesService } from "services/filesService";
import { useCallback, useEffect, useState } from "react";
import useVideoUpload from "hooks/useVideoUpload";
import Button from "common/UIKit/Button";
import { useResponsive } from "hooks/useResponsive";
import { RecordVideoModal } from "common/components/RecordVideoModal";
import {
  ProgressBar,
  RemoveBtn,
} from "common/components/FileUpload/components";
import { TherapistFile } from "types/therapist.types";

const VideoSection = () => {
  const { isMobile } = useResponsive();
  const { data: files } = useSnapshot(filesService);
  const [videos, setVideos] = useState<TherapistFile[]>([]);
  const { uploadProgress, uploadType } = useSnapshot(filesService);

  const {
    existingFilesLogic: { fileUploadProps },
    recordedVideoLogic,
    modeSwitchingLogic,
  } = useVideoUpload();

  useEffect(() => {
    const newVideos: any = files.filter(
      (file) =>
        file.type === "photo_and_video_intro" && file.mime.includes("video")
    );
    const lastVideo = newVideos.slice(newVideos.length - 1);
    setVideos(lastVideo);
  }, [files]);

  const RequestRecordingButton = useCallback(
    () => (
      <Button
        color="green"
        className={styles.startRecordingBtn}
        onClick={modeSwitchingLogic.requestRecording}
      >
        Start recording
      </Button>
    ),
    []
  );

  if (modeSwitchingLogic.isRecording) {
    return (
      <RecordVideoModal
        onClose={modeSwitchingLogic.cancelRecording}
        {...recordedVideoLogic}
      />
    );
  }

  return (
    <div className={styles.videos}>
      <div className={styles.videoPart}>
        {!isMobile && <h2>My Video Intro</h2>}
        {!videos.length && (
          <p className={styles.caption}>
            To set a video intro, please upload or record a video.
          </p>
        )}
        {videos && videos.length > 0 ? (
          videos?.map((file: any) => (
            <VideoPlayer
              key={file.id}
              src={file.file}
              className={styles.player}
              showControls={false}
            />
          ))
        ) : (
          <p className={styles.noVideoPhoto}>
            There are no uploaded videos yet.
          </p>
        )}
        {uploadProgress === null && videos.length > 0 && (
          <RemoveBtn
            className={styles.removeBtn}
            handleRemoveFile={() =>
              fileUploadProps.onRemoveFileById(videos[0].id)
            }
            disabled={fileUploadProps.disabled}
          />
        )}
        {uploadType?.includes("video") && (
          <ProgressBar onRemove={fileUploadProps.onAbortUploading} />
        )}
      </div>
      {!isMobile && (
        <div className={styles.videoPart}>
          {!isMobile && <h2>Upload or Record a New Video</h2>}
          <ControlledFileUpload
            accept="video/*"
            className={styles.dropContainer}
            actionsContainerClassName={styles.fileUploadActions}
            hideAcceptHint
            withoutPreview={true}
            caption="Drag files here to start uploading"
            actions={[
              (e) =>
                BrowseButton({
                  label: "Upload files",
                  startBrowsing: e.startBrowsing,
                }),
              RequestRecordingButton,
            ]}
            {...fileUploadProps}
          />
        </div>
      )}
    </div>
  );
};

export default VideoSection;
