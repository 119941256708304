import { useEffect, useState } from "react";
import { ReactComponent as NotificationIcon } from "assets/images/icons/notification.svg";
import { ReactComponent as NotificationAccentIcon } from "assets/images/icons/notification-accent.svg";
import cn from "classnames";
import { notificationsService } from "services/notificationsService";
import { useSnapshot } from "valtio";
import { NotificationItem } from "./components/NotificationItem";
import { useResponsive } from "hooks/useResponsive";
import { ReactComponent as CrossIcon } from "assets/images/icons/close-square.svg";
import styles from "./Notification.module.scss";

interface Props {
  className?: string;
  menuClassName?: string;
}

export const Notification = ({ className, menuClassName }: Props) => {
  const { isMobile } = useResponsive();
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const { unread, notifications, isFetching } =
    useSnapshot(notificationsService);

  useEffect(() => {
    notificationsService.fetchUnread();
  }, [isFetching]);

  useEffect(() => {
    if (isOpenMenu) {
      notificationsService.list();
    }
  }, [isOpenMenu, isFetching]);

  return (
    <div className={cn(styles.root, className)}>
      <button
        className={styles.notification}
        type="button"
        onClick={() => setIsOpenMenu((prevState) => !prevState)}
      >
        <NotificationIcon className={styles.bell} />
        {unread && <NotificationAccentIcon className={styles.accent} />}
      </button>
      {isOpenMenu && (
        <ul className={cn(styles.menu, menuClassName)}>
          {isMobile && (
            <li className={styles.menuTitle}>
              <span>Notifications</span>
              <CrossIcon onClick={() => setIsOpenMenu(false)} />
            </li>
          )}
          {notifications.length > 0 ? (
            notifications.map((notify) => (
              <NotificationItem notify={notify} key={notify.id} />
            ))
          ) : (
            <li className={cn(styles.item, styles.itemCenter)}>
              No Notifications Yet
            </li>
          )}
        </ul>
      )}
      {isOpenMenu && (
        <div className={styles.layout} onClick={() => setIsOpenMenu(false)} />
      )}
    </div>
  );
};
