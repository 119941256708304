import { DashboardTab } from "./useDashboardTabs";
import { MyAccount, myAccountPath } from "./MyAccount";
import { testsPath, Tests } from "./Tests";
import { Favorites, favoritesPath } from "./Favorites";
import { Messages, messagesPath } from "./Messages";
import { Recommended, recommendedPath } from "./Recommended";
import { Settings, settingsPath } from "./Settings";
import { OrderHistory, orderHistoryPath } from "./OrderHistory";

import { ReactComponent as SinglePerson } from "assets/images/icons/single-person.svg";
import { ReactComponent as TestsIcon } from "assets/images/icons/tests.svg";
import { ReactComponent as FavoritesIcon } from "assets/images/icons/heart.svg";
import { ReactComponent as MessagesIcon } from "assets/images/icons/message.svg";
import { ReactComponent as RecommendedIcon } from "assets/images/icons/star.svg";
import { ReactComponent as SettingsIcon } from "assets/images/icons/gear.svg";
import { ReactComponent as DoubleWallet } from "assets/images/icons/double-wallet.svg";

export const tabs: DashboardTab[] = [
  {
    icon: SinglePerson,
    label: "My Account",
    value: myAccountPath,
    component: MyAccount,
  },
  {
    icon: TestsIcon,
    label: "Tests",
    value: testsPath,
    component: Tests,
  },
  {
    icon: FavoritesIcon,
    label: "Favorites",
    value: favoritesPath,
    component: Favorites,
  },
  {
    icon: MessagesIcon,
    label: "Messages",
    value: messagesPath,
    component: Messages,
  },
  {
    icon: RecommendedIcon,
    label: "Recommended",
    value: recommendedPath,
    component: Recommended,
  },
  {
    icon: SettingsIcon,
    label: "Settings",
    value: settingsPath,
    component: Settings,
  },
  {
    icon: DoubleWallet,
    label: "Order History",
    value: orderHistoryPath,
    component: OrderHistory,
  },
];
