import { ReactNode } from "react";
import Layout from "common/components/Layout/Layout";

export const PatientStepsLayout = ({ children }: { children: ReactNode } ) => (
  <Layout>
    <section className="patient-step">
      <div className="patient-step__wrapper">
        { children }
      </div>
    </section>
  </Layout>
)