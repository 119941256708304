import { FC, useEffect } from "react";
import Layout from "common/components/Layout/Layout";
import Button from "common/UIKit/Button/Button";
import RoundScale from "common/UIKit/RoundScale/RoundScale";
import { AccessController } from "common/components/Layout/AccessController";
import { therapistsPath } from "../therapistsPath";
import { useAuth } from "hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { therapistService } from "services/therapistService";
import { authService } from "services/authService";
import "./TherapistsCompleted.scss";

export const therapistsCompetedPath = "/completed";
export const therapistsCompetedFullPath = `${therapistsPath}${therapistsCompetedPath}`;

export const TherapistsCompleted: FC = () => {
  const { stats } = useSnapshot(therapistService);
  const { user } = useSnapshot(authService);
  const navigate = useNavigate();
  const { isProfileCompleted, setIsProfileCompleted } = useAuth();
  let scaleOptions = {
    diameter: 355,
    strokeWidth: 4,
    textFontSize: 60,
  };
  const { innerWidth } = window;

  if (innerWidth < 576) {
    scaleOptions = {
      diameter: 250,
      strokeWidth: 3,
      textFontSize: 40,
    };
  }

  useEffect(() => {
    if (user?.id) {
      therapistService.getStatsById(user.id);
    }
  }, [user]);

  const onClickGo = () => {
    navigate("/therapists");
    return setIsProfileCompleted(false);
  };

  if (!isProfileCompleted) {
    onClickGo();
  }

  return (
    <AccessController therapist="allowed">
      <Layout mainClass="therapists-completed" withoutHeader>
        <section className="therapists-completed__container">
          <div className="therapists-completed__wrapper">
            <h1 className="therapists-completed__caption title">
              Congratulations!
            </h1>
            <sub className="therapists-completed__subtitle">
              your profile has been completed
            </sub>
            <RoundScale
              scaleValue={stats.profile_completion}
              scaleOptions={scaleOptions}
            />
            <Button
              type="button"
              className="therapists-completed__button"
              size="full"
              onClick={onClickGo}
            >
              Go to your dashboard
            </Button>
          </div>
        </section>
      </Layout>
    </AccessController>
  );
};
