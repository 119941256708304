import { FC, useEffect } from "react";
import isEmail from "validator/lib/isEmail";
import Input from "common/UIKit/Input";
import Button from "common/UIKit/Button";
import { useProfileForm } from "../useProfileForm";
import { PatientFields } from "../../../PatientSteps/util";
import { personalInformationPath } from "../PersonalInformation";
import { useAuth } from "hooks/useAuth";
import { ReactComponent as EditIcon } from "assets/images/icons/edit-white.svg";
import { useModal } from "hooks/useModal";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import styles from "./EditPersonalInformation.module.scss";
import { MessagePopup } from "common/components/MessagePopup";
import { Patient } from "types/patient.types";

export const editPersonalInformationPath = `${personalInformationPath}/edit`;

const coveredFields: PatientFields = [
  "user.first_name",
  "user.last_name",
  "user.mobile_phone",
  "user.email",
];
type Inputs = {
  first_name: string;
  last_name: string;
  mobile_phone: string;
  email: string;
};

export const EditPersonalInformation: FC = () => {
  const { goBack } = useDashboardNavigation("Edit Personal Information", {
    goBack: "pop_path",
  });
  const { register, handleSubmit, errors, isSubmitting, setValue } =
    useProfileForm<Inputs>(coveredFields, goBack);
  const { baseUser } = useAuth();
  const user = useAuth().user as Patient | null;
  const { open } = useModal("CHANGE_EMAIL");
  const phoneChangeModal = useModal("CHANGE_PHONE_NUMBER_IN_PROFILE");

  useEffect(() => {
    user && setValue('mobile_phone', user?.user.mobile_phone);
  }, [user?.user.mobile_phone])

  const handleChangeEmail = () => open();

  return (
    <div className={styles.editPersonalInformation}>
      <MessagePopup />
      <form className={styles.mainForm} onSubmit={handleSubmit}>
        <Input
          label="First Name"
          placeholder=""
          {...register("first_name", {
            required: "This field is required.",
          })}
          error={errors.first_name?.message}
        />
        <Input
          label="Last Name"
          placeholder=""
          {...register("last_name", {
            required: "This field is required.",
          })}
          error={errors.last_name?.message}
        />
        <div className={styles.row}>
          <Input
              className={styles.phone}
              label="Mobile Phone"
              placeholder=""
              value={user?.user.mobile_phone}

              disabled
          />
          {!baseUser?.is_google_user && (
              <button
                  className={styles.changeEmail}
                  onClick={() => phoneChangeModal.open()}
                  type="button"
              >
                <EditIcon className={styles.changeEmailIcon} />
                <div className={styles.changeEmailText}>Change mobile phone</div>
              </button>
          )}
        </div>
        <div className={styles.row}>
          <Input
            type="email"
            label="Email"
            placeholder=""
            {...register("email", {
              required: "This field is required.",
              validate: (value) =>
                !value || isEmail(value) || "This email is incorrect.",
            })}
            error={errors.email?.message}
            disabled
          />
          {!baseUser?.is_google_user && (
            <button
              className={styles.changeEmail}
              onClick={handleChangeEmail}
              type="button"
            >
              <EditIcon className={styles.changeEmailIcon} />
              <div className={styles.changeEmailText}>Change email</div>
            </button>
          )}
        </div>
        <Input
          label="Menthal Healh Role"
          placeholder=""
          value="Patient"
          disabled
        />
        <Button
          type="submit"
          text="Save"
          size="full"
          isLoading={isSubmitting}
        />
      </form>
    </div>
  );
};
