import { AuthLayout } from "../../components";
import styles from "./PasswordReset.module.scss";
import Button from "common/UIKit/Button";
import cn from "classnames";
import { Link } from "react-router-dom";
import { loginPath } from "../../../commonLinks";
import InputPassword, {
  SPECIAL_CHAR_REGEXP,
} from "common/UIKit/InputPassword/InputPassword";
import { usePasswordReset } from "./usePasswordReset";
import { ErrorMessage } from "common/UIKit/ErrorMessage";

export const passwordResetPath = "/password-reset";

export const PasswordReset = () => {
  const {
    register,
    getValues,
    dirtyFields,
    handleSubmit,
    errors,
    isSubmitting,
    userType,
  } = usePasswordReset();

  return (
    <AuthLayout
      signTitle="Reset password"
      signPostTitle="Please enter a new password"
      signRightTitle={"Find the best match \nfor your therapy"}
    >
      <form className={styles.form} onSubmit={handleSubmit}>
        {errors.global && <ErrorMessage>{errors.global}</ErrorMessage>}
        <InputPassword
          label="Password"
          placeholder=""
          className={styles.input}
          validateStrength={dirtyFields.password}
          {...register("password", {
            required: "Required field",
            minLength: { value: 8, message: "Min length is 8" },
            validate: {
              hasAlpha: (value) =>
                !!value.match(/\w/g) || "Need at least one letter",
              hasNumeric: (value) =>
                !!value.match(/\d/g) || "Need at least one number",
              hasSymbols: (value) =>
                !!value.match(SPECIAL_CHAR_REGEXP) || "Need special sign",
            },
          })}
          error={dirtyFields.password ? "" : errors.password?.message}
        />
        <InputPassword
          label="Confirm password"
          placeholder=""
          className={styles.input}
          {...register("confirmPassword", {
            deps: "password",
            required: "Required field",
            validate: {
              sameAsPassword: (value) =>
                value === getValues("password") || "Passwords do not match",
            },
          })}
          error={errors.confirmPassword?.message}
        />
        <Button
          type="submit"
          text="Submit"
          size="full"
          isLoading={isSubmitting}
        />
      </form>
      <p className={cn("sign-help", styles.helpMessage)}>
        Already have an account?{" "}
        <Link to={`/${userType}${loginPath}`} className="g-underline">
          Log in
        </Link>
      </p>
    </AuthLayout>
  );
};
