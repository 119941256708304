import { Routes, Route } from "react-router-dom";
import { HomePage, homePath } from "pages/HomePage";
import {
  loadingPath,
  LoadingWithRedirect,
} from "pages/Auth/LoadingWithRedirect";
import { AccessController } from "common/components/Layout/AccessController";
import { Overview } from "pages/Therapists/SubscriptionPlans/Overview";
import {
  TherapistRate,
  therapistRatePath,
} from "pages/Auth/TherapistRate/TherapistRate";
import { Redirect } from "pages/Auth/Redirect";
import { therapistPatientPath } from "pages/Therapists/TherapistPatient";
import {
  TherapistLanding,
  therapistLandingPath,
} from "pages/Auth/TherapistLanding";
import { Patients, patientsPath } from "pages/Patients";
import { Therapists, therapistsPath } from "pages/Therapists";
import { articlesPath } from "pages/Articles";
import Articles from "pages/Articles/Articles";
import { articlePath } from "pages/Articles/Article";
import Article from "pages/Articles/Article/Article";
import { verificationPath, VerificationSent } from "pages/VerificationSent";
import {
  ConfirmPhone,
  confirmPhonePath,
} from "pages/ConfirmPhone/ConfirmPhone";
import {
  PasswordReset,
  passwordResetPath,
} from "pages/Auth/Forgot/PasswordReset";
import { ConfirmEmail, confirmEmailPath } from "pages/Auth/Forgot/ConfirmEmail";
import {
  PasswordResetSuccess,
  passwordResetSuccessPath,
} from "pages/Auth/Forgot/PasswordResetSuccess";
import { forgotPath, loginPath, signupPath } from "pages/commonLinks";
import { Login } from "pages/Auth/Login";
import { SignUp } from "pages/Auth/SignUp";
import { Forgot } from "pages/Auth/Forgot/Forgot";
import { Verification, verifiedPath } from "pages/Verification";
import { AlmostReady, almostReadyPath } from "pages/AlmostReady";
import {
  TherapistsListing,
  therapistsListingPath,
} from "pages/Patients/TherapistsListing";
import {
  modifyPath,
  removePath,
  RemoveTherapist,
} from "pages/RemoveTherapist/RemoveTherapist";
import {
  PatientsTherapist,
  patientsTherapistPremiumPath,
} from "pages/Patients/PatientsTherapist";
import { About, aboutPath } from "pages/About";
import { ContactUs, contactUsPath } from "pages/ContactUs";
import { PrivacyPolicy, privacyPolicyPath } from "pages/PrivacyPolicy";
import { TermsOfUse, termsOfUsePath } from "pages/TermsOfUse";
import { FAQ, faqPath } from "pages/FAQ";
import { ErrorPage } from "pages/ErrorPage";
import { SuccessfulPage } from "pages/SuccessfulPage";

export const Routing = () => {
  return (
    <Routes>
      <Route path={homePath} element={<HomePage />} />
      <Route path={`${homePath}home-backup`} element={<HomePage />} />
      <Route path={loadingPath} element={<LoadingWithRedirect />} />
      <Route
        path={"/therapists/subscription-plans"}
        element={
          <AccessController unauthorized="allowed" therapist="allowed">
            <Overview headerAction="goBack" />
          </AccessController>
        }
      />
      <Route path={`${therapistRatePath}/:id`} element={<TherapistRate />} />
      <Route
        path={`tr/:id`}
        element={<Redirect to={therapistRatePath} withId />}
      />
      <Route
        path={`pd/:id`}
        element={<Redirect to={`/therapists${therapistPatientPath}`} withId />}
      />

      <Route path={therapistLandingPath} element={<TherapistLanding />} />
      <Route path={`${patientsPath}/*`} element={<Patients />} />
      <Route path={`${therapistsPath}/*`} element={<Therapists />} />
      <Route path={articlesPath} element={<Articles />} />
      <Route path={articlePath} element={<Article />} />
      <Route path={verificationPath} element={<VerificationSent />} />
      <Route path={confirmPhonePath} element={<ConfirmPhone />} />
      <Route path={passwordResetPath} element={<PasswordReset />} />
      <Route path={confirmEmailPath} element={<ConfirmEmail />} />
      <Route
        path={passwordResetSuccessPath}
        element={<PasswordResetSuccess />}
      />
      <Route path={`/:userType${loginPath}`} element={<Login />} />
      <Route path={`/:userType${signupPath}`} element={<SignUp />} />
      <Route path={forgotPath} element={<Forgot />} />
      <Route path={verifiedPath} element={<Verification />} />
      <Route path={almostReadyPath} element={<AlmostReady />} />
      <Route path={therapistsListingPath} element={<TherapistsListing />} />
      <Route path={removePath} element={<RemoveTherapist />} />
      <Route path={modifyPath} element={<RemoveTherapist />} />
      {/*<Route*/}
      {/*  path={patientsTherapistPremiumPath}*/}
      {/*  element={<PatientsTherapist />}*/}
      {/*/>*/}
      <Route
        path={patientsTherapistPremiumPath}
        element={<PatientsTherapist />}
      />
      <Route path={`td/:id`} element={<PatientsTherapist />} />
      <Route path={aboutPath} element={<About />} />
      <Route path={contactUsPath} element={<ContactUs />} />
      <Route path={privacyPolicyPath} element={<PrivacyPolicy />} />
      <Route path={termsOfUsePath} element={<TermsOfUse />} />
      <Route path={faqPath} element={<FAQ />} />
      <Route path="*" element={<ErrorPage />} />
      <Route path="/payment-successful" element={<SuccessfulPage />} />
    </Routes>
  );
};
