import { FC } from "react";

import { FormatProfileField } from "common/components/FormatProfileField";
import { useAuth } from "hooks/useAuth";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { Patient } from "types/patient.types";

import styles from "./PersonalInformationMobile.module.scss";

export const PersonalInformationMobile: FC = () => {
  useDashboardNavigation("Personal Information", {
    goBack: "pop_path",
    edit: "append_edit",
  });
  const user = useAuth().user as Patient;

  return (
    <div className={styles.personalInformationMobile}>
      <div className={styles.field}>
        <h2>Full Name</h2>
        <p>
          <FormatProfileField maxLength={40}>
            {`${user.user.first_name} ${user.user.last_name}`}
          </FormatProfileField>
        </p>
      </div>

      <div className={styles.field}>
        <h2>Email Address</h2>
        <p>
          <FormatProfileField shouldLimitLength={false}>
            {user.user.email}
          </FormatProfileField>
        </p>
      </div>

      <div className={styles.field}>
        <h2>Mobile Phone</h2>
        <p>
          <FormatProfileField>{user.user.mobile_phone}</FormatProfileField>
        </p>
      </div>

      <div className={styles.field}>
        <h2>Mental Health Role</h2>
        <p>
          <FormatProfileField>Patient</FormatProfileField>
        </p>
      </div>
    </div>
  );
};
