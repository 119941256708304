import classNames from "classnames";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSnapshot } from "valtio";

import Button from "common/UIKit/Button";
import { Pagination } from "common/UIKit/Pagination";
import { SortArrow } from "common/components/SortArrow";
import { Spinner } from "common/components/Spinner/Spinner";

import { ratePurchaseService } from "services/ratePurchaseService";

import { onRequestToNewWindow } from "common/utils/async";

import { onSortChange, stringToDate, getFullName } from "./helpers";

import styles from "./OrdersTable.module.scss";
import { UserType } from "types/auth.types";

interface Props {
  userRole: UserType;
  emptyMessage?: string;
}

export const OrdersTable = ({ userRole, emptyMessage }: Props) => {
  const { data, isFetching, page, pageCount, isInvoiceFetched, sort } =
    useSnapshot(ratePurchaseService);

  useEffect(() => {
    ratePurchaseService.fetch(false);
  }, []);

  if (isFetching)
    return (
      <div className={styles.spinner}>
        <Spinner />
      </div>
    );

  if (!data.length)
    return (
      <p className={styles.empty}>
        {emptyMessage || "Purchase history is empty"}
      </p>
    );

  return (
    <>
      <table className={styles.table}>
        <thead className={styles.tableHeader}>
          <tr className={styles.tableLine}>
            <th
              className={classNames(
                styles.tableHead,
                styles.tableLeft,
                styles.sortable
              )}
              onClick={() => onSortChange("paid_at")}
            >
              <span>DATE</span>
              <SortArrow name="paid_at" sort={sort} />
            </th>
            <th
              className={classNames(
                styles.tableHead,
                styles.tableLeft,
                styles.sortable
              )}
              onClick={() => onSortChange("fee")}
            >
              <span>AMOUNT</span>
              <SortArrow name="fee" sort={sort} inverted />
            </th>
            <th className={classNames(styles.tableHead, styles.tableLeft)}>
              DESCRIPTION
            </th>
            <th className={styles.tableHead}>
              {/* if we view table as therapist show client column */}
              {userRole === "therapist" ? "CLIENT" : "THERAPIST"}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody className={styles.tableBody}>
          {data.map((item) => (
            <tr key={item.id} className={styles.tableLine}>
              <td className={classNames(styles.tableLeft, styles.tableCell)}>
                {stringToDate(item.paid_at)}
              </td>
              <td
                className={classNames(styles.tableCell, styles.tableCellCost)}
              >
                ${Number(item.fee).toFixed(2)}
              </td>
              <td className={styles.tableCell}>{item.name}</td>
              <td
                className={classNames(styles.tableCell, styles.tableCellImage)}
              >
                {/* if we view table as therapist show client name
                otherwise show therapist name */}
                {userRole === "therapist" ? (
                  item.patient ? (
                    <>
                      {item.patient.file && (
                        <img
                          className={styles.tableImage}
                          src={item.patient.file}
                          alt={getFullName(item.patient)}
                        />
                      )}
                      <Link to={`/therapists/patient/${item.patient.id}`}>
                        {getFullName(item.patient)}
                      </Link>
                    </>
                  ) : (
                    <p className={styles.deletedUser}>Deleted Patient</p>
                  )
                ) : item.therapist ? (
                  <>
                    {item.therapist.file && (
                      <img
                        className={styles.tableImage}
                        src={item.therapist.file}
                        alt={getFullName(item.therapist)}
                      />
                    )}
                    <Link to={`/therapist/${item.therapist.id}`}>
                      {getFullName(item.therapist)}
                    </Link>
                  </>
                ) : (
                  <p className={styles.deletedUser}>Deleted Therapist</p>
                )}
              </td>
              <td className={classNames(styles.tableCell)}>
                <Button
                  className={styles.tableButton}
                  text="View invoice"
                  onClick={() =>
                    onRequestToNewWindow(
                      ratePurchaseService.getInvoice(item.id)
                    )
                  }
                  isLoading={!isInvoiceFetched}
                  disabled={!item.invoice}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        currentPage={page}
        goTo={(a) => ratePurchaseService.goTo(a)}
        pageCount={pageCount}
      />
    </>
  );
};
