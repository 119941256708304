import { FC, useState } from "react";
import cn from "classnames";
import { Link, useNavigate } from "react-router-dom";
import { isTherapist } from "services/authService";
import { Logo } from "./Logo/Logo";
import Button from "../../UIKit/Button/Button";
import { useAuth } from "hooks/useAuth";
import { useResponsive } from "hooks/useResponsive";
import { UserProfile } from "../UserProfile/UserProfile";
import { therapistsPath } from "pages/Therapists";
import { subscriptionPlansPath } from "pages/Therapists/SubscriptionPlans";
import chevronRight from "assets/images/icons/chevron-circle-right.svg";
import { ReactComponent as CloseIcon } from "assets/images/icons/close-square.svg";
import { useModal } from "hooks/useModal";
import { DashboardTab } from "common/components/DashboardLayout/useDashboardTabs";
import { isChatAccess } from "services/authService";
import "./Header.scss";
import ChatStatusButton from "../DashboardLayout/MobileHeader/components/ChatStatusButton";
import { Availability, availabilityPath } from "pages/Therapists/TherapistsDashboard/Settings/Availability";
import { ReactComponent as Category } from "assets/images/icons/category.svg";
import { Notification } from "common/components/Notification/Notification";

interface Props {
  className?: string;
  tabs?: DashboardTab[];
  mainTab?: boolean
}

const statusButton = {
  icon: Category,
  label: <ChatStatusButton />,
  value: availabilityPath,
  component: Availability
}

export const Header: FC<Props> = ({ className, tabs, mainTab }) => {
  const { authStatus, user } = useAuth();
  const navigate = useNavigate();
  const { isMobile } = useResponsive();
  const [showMenu, setShowMenu] = useState(false);
  const { open } = useModal("CTA_POPUP");

  return (
      <header className={cn("header", className)}>
        <div className="header__content">
          {tabs && tabs.length > 0 ? (
              <label
                  className="header__burger"
                  htmlFor="header__toggle"
                  onClick={() => setShowMenu(true)}
              />
          ) : null}
          <Logo/>
          <nav className={cn("header__nav", showMenu && isMobile && "active")}>
            {isMobile && (
                <div className="header__row">
                  <Logo/>
                  <CloseIcon onClick={() => setShowMenu(false)}/>
                </div>
            )}
            {tabs && tabs.length > 0 ? (
                <ul className="header__ul">
                  {user && isTherapist(user)
                      && <li className="header__li">
                        <Link
                            className="header__link"
                            to={`/therapists/dashboard/settings${statusButton.value}`}
                            onClick={() => setShowMenu(false)}
                        >
                          {statusButton.label}
                        </Link>
                      </li>}
                  {tabs.map((tab) => (
                      <li className="header__li">
                        <Link
                            className="header__link"
                            to={`/${
                                user && isTherapist(user)
                                    ? "therapists/dashboard"
                                    : "patients/dashboard"
                            }${tab.value}`}
                            onClick={() => setShowMenu(false)}
                        >
                          {tab.label}
                        </Link>
                      </li>
                  ))}
                </ul>
            ) : null}
            {isMobile && user && isTherapist(user) && !isChatAccess(user) ? (
                <div
                    className="header__upgrade-to-premium"
                    onClick={() =>
                        navigate(`${therapistsPath}${subscriptionPlansPath}`)
                    }
                    role="link"
                >
                  <h5>Upgrade to Premium</h5>
                  <p>
                    Better position at search results, quick access to the chat for
                    patients and more recommendations.
                  </p>
                  <img src={chevronRight} alt="chevron-right"/>
                </div>
            ) : null}
          </nav>
          {mainTab && <Notification className="mobileNotification"/>}
          {authStatus === "loggedIn" ? (
              <UserProfile/>
          ) : (
              <Button
                  className="header__btn login"
                  size="auto"
                  onClick={() => open()}
              >
                <span>Login</span>
              </Button>
          )}
        </div>
      </header>
  );
};

export default Header;
