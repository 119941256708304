import { FC, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { FieldError, RegisterOptions, useForm } from "react-hook-form";
import { AxiosResponse } from "axios";
import { useSnapshot } from "valtio";

import { TestContext } from "contexts/TestContext";
import { TestError, TestInputs, TestQuestion } from "types/testProcess.types";
import { testProcessService } from "services/testProcessService";
import { testAttemptListService } from "services/testAttemptListService";
import {
  formValuesToTestAnswers,
  testAttemptService,
} from "services/testAttemptService";
import { TestAttemptStatus } from "types/testAttempt.types";

export const TestProvider: FC = ({ children }) => {
  const { id } = useParams();
  const testAttemptId = id === "new" ? 0 : +id!;
  const [search] = useSearchParams();

  const { test } = useSnapshot(testProcessService);

  const {
    handleSubmit,
    register,
    control,
    formState,
    setValue,
    reset,
    setError,
  } = useForm<TestInputs>();
  const errors = formState.errors as Record<string, FieldError | undefined>;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [globalError, setGlobalError] = useState<FieldError>();

  const registerQuestion = (
    question: TestQuestion,
    options?: RegisterOptions
  ) =>
    register(question.id.toString(), {
      ...options,
      required: {
        value: question.required,
        message: "This field is required",
      },
    });

  const startNewAttempt = async () => {
    reset();

    if (testAttemptId) {
      const testAttempt = await testAttemptService.getById(
        testAttemptId,
        false
      );

      testProcessService.getById(testAttempt.test.id);

      testAttempt.test_answers?.forEach((answer) => {
        const newValue = Array.isArray(answer.value)
          ? [...answer.value]
          : (answer.value as string | number);
        setValue(answer.question.toString(), newValue);
      });
    } else {
      testProcessService.getById(Number(search.get("test_id")));
    }
  };

  const getSubmitFunction = (
    status: TestAttemptStatus,
    onSuccess: (attemptId: number) => void
  ) =>
    handleSubmit(async (values) => {
      const changedAttempt = {
        status,
        test_answers: formValuesToTestAnswers(values),
      };
      try {
        await setIsSubmitting(true);

        const attemptId = testAttemptId
          ? testAttemptId
          : (await testAttemptService.start(Number(search.get("test_id")))).id;

        const response = await testAttemptService.patch(
          attemptId,
          changedAttempt
        );

        testAttemptService.entity = response;
        await testAttemptListService.fetch(false);
        await setGlobalError(undefined);
        onSuccess(attemptId);
      } catch (error) {
        const { data } = error as AxiosResponse<TestError>;
        data.non_field_errors &&
          setGlobalError({
            message: data.non_field_errors.join(". "),
            type: "custom",
          });
        data.test_answers?.forEach((errors, index) => {
          let message = "";
          Object.values(errors).forEach(
            (value) => (message += value.join(". "))
          );

          setError(changedAttempt.test_answers[index].question.toString(), {
            message,
            type: "custom",
          });
        });
      } finally {
        setIsSubmitting(false);
      }
    });

  useEffect(() => {
    startNewAttempt();
  }, [testAttemptId, test]);

  return (
    <TestContext.Provider
      value={{
        attemptId: testAttemptId,
        test,
        register,
        control,
        errors: { ...errors, global: globalError },
        registerQuestion,
        getSubmitFunction,
        startNewAttempt,
        isSubmitting,
      }}
    >
      {children}
    </TestContext.Provider>
  );
};
