import { FC, useCallback, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import cn from "classnames";

import Button from "common/UIKit/Button";
import { LikeButton } from "common/UIKit/LikeButton";
import { Avatar } from "common/UIKit/Avatar";

import { Availability } from "common/components/Availability";
import { FormatProfileField } from "common/components/FormatProfileField";
import { StateLicenseListItem } from "common/components/StateLicenseListItem";

import { patientsTherapistPremiumFullPath } from "pages/Patients/PatientsTherapist";

import { TherapistListItem } from "types/therapist.types";
import { UserAuthStatus } from "types/auth.types";

import { therapistListingService } from "services/therapistListingService";
import { favoriteTherapistsService } from "services/favoriteTherapistsService";
import { isChatAccess } from "services/authService";

import {
  areaToString,
  formatPhone,
  getAvatar,
  therapistName,
} from "common/utils";
import { openMessageModalAfterLogin } from "utils/utils";

import { ReactComponent as MarkerIcon } from "assets/images/icons/map-marker-fill.svg";

import { useDictionaryItemNames } from "hooks/useDictionaryItemNames";
import { useResponsive } from "hooks/useResponsive";
import { useModal } from "hooks/useModal";

import styles from "./ListItem.module.scss";
import { useCommunication } from "hooks/useCommunication";

interface Props {
  className?: string;
  therapist: TherapistListItem;
  isTherapist?: boolean | null;
  currentUserStatus: UserAuthStatus;
}

const ListItem: FC<Props> = ({
  className,
  therapist,
  isTherapist,
  currentUserStatus,
}) => {
  const { pathname } = useLocation();
  const { openChantConnect } = useCommunication();
  const {
    first_name,
    last_name,
    photos,
    primary_credential_is_verified,
    title,
    in_person_session_availability,
    online_chat_sessions_availability,
    telephone_session_availability,
    video_session_availability,
    personal_statement_short_description,
    mobile_phone,
    // phone,
    is_my_favourite,
    id,
    chat_status,
    professional_specialties,
    subscription_plan,
    hide_mobile_phone_number,
    source,
    verified_state_licenses,
    business_info,
  } = therapist;
  const { hide_company_address } = business_info || {};
  const businessAddress = areaToString(business_info);
  const isLicensedInState =
    verified_state_licenses.findIndex(
      ({ state }) => state === business_info.state
    ) >= 0;

  const withAvailableOptions =
    in_person_session_availability ||
    online_chat_sessions_availability ||
    telephone_session_availability ||
    video_session_availability;

  const withStatus =
    isChatAccess(therapist) && therapist.online_chat_sessions_availability;
  const { open } = useModal("LOGGED_AS_THERAPIST");

  const professionalSpecialtyName = useDictionaryItemNames(
    "ProfessionalSpecialties",
    professional_specialties
  );

  const phoneToDisplay = subscription_plan.chat_access
    ? (!business_info.hide_company_phone_number && business_info.phone) ||
      (!hide_mobile_phone_number && mobile_phone)
    : business_info.phone || mobile_phone;
  const { isMobile } = useResponsive();

  useEffect(() => {
    openMessageModalAfterLogin(therapist);
  }, []);

  const onMessage = () => {
    isTherapist ? open() : openChantConnect(therapist);
  };

  const onLike = useCallback(async () => {
    try {
      await therapistListingService.like(id);
      favoriteTherapistsService.list(false);
    } catch (error) {
      console.error(error);
    }
  }, [id]);

  return (
    <div className={cn(styles["therapist-card"], className)}>
      <div className={styles["therapist-card__left"]}>
        <Avatar
          className={styles["therapist-card__left-avatar"]}
          file={getAvatar(photos)}
          alt={`${first_name} ${last_name}`}
          chat_status={withStatus ? chat_status : undefined}
          onClick={withStatus ? onMessage : undefined}
          id={id}
          planName={subscription_plan.name}
        />
      </div>
      <div className={styles["therapist-card__main"]}>
        <div className={styles["therapist-card__caption"]}>
          <Link
            to={patientsTherapistPremiumFullPath.replace(":id", String(id))}
            state={{ prevPage: pathname }}
          >
            <div className={styles["therapist-card__name"]}>
              <FormatProfileField maxLength={50}>
                {therapistName({ first_name, last_name })}
              </FormatProfileField>
              {therapist.company_name && therapist.use_company_name_also && (
                <span className={styles["therapist-card__company"]}>
                  {therapist.company_name}
                </span>
              )}
            </div>
          </Link>
          {primary_credential_is_verified && (
            <div className={styles["therapist-card__verified"]}>
              <i className={styles["therapist-card__verified-icon"]} />
              Verified
            </div>
          )}
          {!isTherapist && currentUserStatus !== "loggedOut" ? (
            <LikeButton
              className={styles["therapist-card__like"]}
              isLiked={is_my_favourite}
              onClick={onLike}
            />
          ) : null}
          {isChatAccess(therapist) && (
            <Availability
              className={styles["therapist-card__availability"]}
              isAvailable={withAvailableOptions}
            />
          )}
        </div>
        {source.includes("imported") && professionalSpecialtyName.length > 0 ? (
          <div className={styles["therapist-card__info"]}>
            <div className={styles["therapist-card__info-tags"]}>
              {professionalSpecialtyName.filter((item) => item).join(", ")}
            </div>
          </div>
        ) : null}
        {businessAddress && isLicensedInState && !hide_company_address ? (
          <div className={styles["therapist-card__info-address"]}>
            <MarkerIcon
              className={styles["therapist-card__info-address-icon"]}
            />
            <FormatProfileField
              className={styles["therapist-card__info-address-text"]}
              maxLength={50}
            >
              {businessAddress}
            </FormatProfileField>
          </div>
        ) : null}
        {verified_state_licenses.slice(0, 3).map((license) => (
          <StateLicenseListItem
            key={`${license.state}-${license.number}`}
            license={license}
          />
        ))}
        {withAvailableOptions && (
          <div
            className={cn(styles["therapist-card__options"], {
              [styles["therapist-card__options-margin"]]:
                !therapist.company_name &&
                professionalSpecialtyName.length === 0,
            })}
          >
            <span>Therapy Options:</span>
            {in_person_session_availability && (
              <button
                className={cn(
                  styles["therapist-card__options-item"],
                  styles["person-icon"]
                )}
                aria-label="In-Person"
              />
            )}
            {telephone_session_availability && (
              <button
                className={cn(
                  styles["therapist-card__options-item"],
                  styles["phone-icon"]
                )}
                aria-label="Telephone"
              />
            )}
            {video_session_availability && (
              <button
                className={cn(
                  styles["therapist-card__options-item"],
                  styles["video-icon"]
                )}
                aria-label="Video"
              />
            )}
            {online_chat_sessions_availability && (
              <button
                className={cn(
                  styles["therapist-card__options-item"],
                  styles["chat-icon"]
                )}
                aria-label="Chat Connect"
              />
            )}
          </div>
        )}
        <p className={styles["therapist-card__description"]}>
          <FormatProfileField
            maxLength={200}
            isExpandable
            fallbackText="No description added yet"
            description={true}
          >
            {personal_statement_short_description}
          </FormatProfileField>
        </p>
      </div>
      <div className={styles["therapist-card__right"]}>
        {Boolean(phoneToDisplay) && (
          <a
            href={phoneToDisplay ? `tel:${phoneToDisplay}` : ""}
            className={cn(
              styles["therapist-card__right-call"],
              !phoneToDisplay && styles["disabled"]
            )}
          >
            {phoneToDisplay && formatPhone(phoneToDisplay)}
          </a>
        )}
        {isChatAccess(therapist) && (
          <Button
            className={cn(
              styles["therapist-card__right-msg"],
              styles["btn-premium"],
              styles[`btn-${chat_status}`]
            )}
            onClick={onMessage}
          >
            <i className={styles["therapist-card__msg-icon"]} />
            <FormatProfileField maxLength={30}>
              {chat_status !== "offline"
                ? "Chat Connect"
                : `Email ${therapistName({ title, last_name })}`}
            </FormatProfileField>
          </Button>
        )}
        <Button
          className={styles["therapist-card__right-profile"]}
          isLink
          to={patientsTherapistPremiumFullPath.replace(":id", String(id))}
          state={{ prevPage: pathname }}
          color={isMobile ? "turquoise" : "greenlight"}
        >
          View profile
        </Button>
      </div>
    </div>
  );
};

export default ListItem;
