import { AgeCode, Ethnicity, Faith, TherapyType } from "constants/options";
import { BaseUser } from "./auth.types";
import {TherapistFile} from "./therapist.types";

export type PatientId = string;
export type PatientFile = TherapistFile;
export const ALL_THERAPIST_PREFERENCES = [
  "none",
  "male",
  "female",
  "christian",
  "older",
  "nonreligious",
  "spanish",
  "color",
] as const;
export type TherapistPreference = typeof ALL_THERAPIST_PREFERENCES[number];

export type PreferredPrice = "min" | "avg" | "max" | "flex";

export type AppointmentDate = "none" | "immediately" | "today" | "next_couple_days";

export const ALL_PREFERRED_SEXUALITIES = ["none", "straight", "lesbian", "bisexual", "gay", "lgbtq"] as const;
export type PreferredSexuality = typeof ALL_PREFERRED_SEXUALITIES[number];

export interface Patient {
  id: PatientId;
  user: BaseUser;
  zip_code: string;
  readonly therapy_types: TherapyType[];
  issues: string[];
  readonly therapist_preferences: TherapistPreference[];
  age: AgeCode;
  preferred_ethnicities: Ethnicity;
  preferred_price: PreferredPrice;
  readonly  preferred_sexualities: PreferredSexuality[];
  readonly preferred_languages: string[];
  preferred_faith: Faith;
  appointment_date: AppointmentDate;
  file: PatientFile | null;
}
