import { FC } from "react";
import { TestBlock } from "types/testProcess.types";
import { Question } from "../Question";
import styles from "./Block.module.scss";

interface Props {
  block: TestBlock;
  disabled?: boolean;
  placeholder?: string;
}

export const Block: FC<Props> = ({ block, disabled, placeholder }) => {
  return (
    <div className={styles.testBlock}>
      {block.general_question && <h2>{block.general_question}</h2>}
      <div className={styles.container}>
        {block.questions.map((question) => (
          <Question
            question={question}
            isIntro={block.is_intro}
            key={question.id}
            disabled={disabled}
            placeholder={placeholder}
          />
        ))}
      </div>
    </div>
  );
};
