import { EditHeader } from "common/components/EditHeader";
import { stepsUi } from "pages/Therapists/TherapistSteps/components";
import { Controller } from "react-hook-form";
import Button from "common/UIKit/Button";
import AppSelect from "common/UIKit/Select";
import {
  ListOption,
  StateOption,
  STATE_OPTIONS,
  TITLE_OPTIONS,
} from "constants/options";
import { Gender, Therapist, Title } from "types/therapist.types";
import Input from "common/UIKit/Input";
import { ErrorMessage } from "common/UIKit/ErrorMessage";
import Radiobutton from "common/UIKit/Radiobutton";
import Checkbox from "common/UIKit/Checkbox";
import { useProfileForm } from "../../useProfileForm";
import { TherapistFields } from "pages/Therapists/TherapistSteps/util";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { ReactComponent as EditIcon } from "assets/images/icons/edit-white.svg";
import isEmail from "validator/lib/isEmail";
import { useAuth } from "hooks/useAuth";
import { useModal } from "hooks/useModal";
import { EmailPopup } from "common/components/EmailPopup";
import {
  cityValidator,
  streetAddressValidator,
  zipCodeValidator,
} from "common/validators/addressValidators";
import { InputSmartSearch } from "common/UIKit/InputSmartSearch/InputSmartSearch";
import styles from "./EditPersonalInformation.module.scss";
import { MessagePopup } from "common/components/MessagePopup";


export const editPersonalInformation = "/edit-personal-information";

const coveredFields: TherapistFields = [
  "user.first_name",
  "user.last_name",
  "user.email",
  "user.mobile_phone",
  "user.hide_mobile_phone_number",
  "middle_name",
  "title",
  "credentials_choice",
  "gender",
  "hide_personal_address",
  "state",
  "zip_code",
  "street_address",
  "city",
  "longitude",
  "latitude",
];

type Inputs = {
  title: Title;
  first_name: string;
  middle_name: string;
  last_name: string;
  credentials_choice: string;
  gender: Gender;
  practice_name: string;
  email: string;
  mobile_phone: string;
  hide_personal_address: boolean;
  hide_mobile_phone_number: boolean;
  state?: string;
  zip_code: string;
  street_address: string;
  city: string;
  longitude: number;
  latitude: number;
  user: {
    mobile_phone: string;
  };
};

export const EditPersonalInformation = () => {
  useDashboardNavigation("Edit Personal Info", {
    goBack: "pop_path",
  });
  const { register, control, errors, handleSubmit, setValue } =
      useProfileForm<Inputs>(coveredFields);
  const { baseUser } = useAuth();
  const user = useAuth().user as Therapist | null;
  const { open } = useModal("CHANGE_EMAIL");
  const phoneChangeModal = useModal("CHANGE_PHONE_NUMBER_IN_PROFILE");
  const canHidePhone = user?.subscription_plan.chat_access;

  const handleChangeEmail = () => open();

  return (
      <section className={styles.editSpecialties}>
        <MessagePopup />
        <EditHeader />
        <form className={styles.editSpecialtiesInner} onSubmit={handleSubmit}>
          <stepsUi.Grid>
            <stepsUi.Row label="Title">
              <Controller
                  control={control}
                  name="title"
                  render={({ field, fieldState }) => (
                      <AppSelect
                          placeholder="Select"
                          {...field}
                          options={TITLE_OPTIONS}
                          value={TITLE_OPTIONS.find((o) => o.value === field.value)}
                          onChange={(newValue) => {
                            const { value } = newValue as ListOption<Title>;
                            field.onChange(value);
                          }}
                          error={fieldState.error?.message}
                      />
                  )}
              />
            </stepsUi.Row>
            <stepsUi.Row>
              <Input
                  type="text"
                  placeholder=""
                  label="First name"
                  {...register("first_name", { required: "Required field" })}
                  error={errors.first_name?.message}
              />
            </stepsUi.Row>
          </stepsUi.Grid>
          <stepsUi.Grid>
            <stepsUi.Row>
              <Input
                  type="text"
                  placeholder=""
                  label="Middle name"
                  {...register("middle_name")}
                  error={errors.middle_name?.message}
              />
            </stepsUi.Row>
            <stepsUi.Row>
              <Input
                  type="text"
                  placeholder=""
                  label="Last name"
                  {...register("last_name", { required: "Required field" })}
                  error={errors.last_name?.message}
              />
            </stepsUi.Row>
          </stepsUi.Grid>
          <stepsUi.Grid>
            <stepsUi.Row>
              <Input
                  type="text"
                  placeholder=""
                  label="Credentials"
                  maxLength={24}
                  {...register("credentials_choice")}
                  error={errors.credentials_choice?.message}
                  description="Specify the initials to be included after your name (PhD, MA, LCSW,
                etc.)"
                  descriptionAlignment="left"
              />
            </stepsUi.Row>
          </stepsUi.Grid>
          <stepsUi.Row label="Gender">
            <stepsUi.Container>
              <Radiobutton
                  className={styles.radiobutton}
                  {...register("gender")}
                  value="female"
                  withBorder
              >
                Female
              </Radiobutton>
              <Radiobutton
                  className={styles.radiobutton}
                  {...register("gender")}
                  value="male"
                  withBorder
              >
                Male
              </Radiobutton>
              <Radiobutton
                  className={styles.radiobutton}
                  {...register("gender")}
                  value="non_binary"
                  withBorder
              >
                Non Binary
              </Radiobutton>
            </stepsUi.Container>
            {errors.gender?.message && (
                <ErrorMessage>{errors.gender?.message}</ErrorMessage>
            )}
          </stepsUi.Row>
          <stepsUi.Grid>
            <stepsUi.Row>
              <Input
                  type="email"
                  label="Email"
                  readOnly
                  placeholder=""
                  {...register("email", {
                    required: "This field is required.",
                    validate: (value) =>
                        !value || isEmail(value) || "This email is incorrect.",
                  })}
                  error={errors?.email?.message}
              />
            </stepsUi.Row>
            <stepsUi.Row>
              {!baseUser?.is_google_user && (
                  <button
                      className={styles.changeEmail}
                      onClick={handleChangeEmail}
                      type="button"
                  >
                    <EditIcon className={styles.changeEmailIcon} />
                    <div className={styles.changeEmailText}>Change email</div>
                  </button>
              )}
            </stepsUi.Row>
          </stepsUi.Grid>
          <stepsUi.Grid>
            <stepsUi.Row>
              <Input
                  type="phone"
                  label="Mobile Phone"
                  readOnly
                  {...register("mobile_phone")}
              />
            </stepsUi.Row>
            <stepsUi.Row>
              {!baseUser?.is_google_user && (
                  <button
                      className={styles.changeEmail}
                      onClick={() => phoneChangeModal.open()}
                      type="button"
                  >
                    <EditIcon className={styles.changeEmailIcon} />
                    <div className={styles.changeEmailText}>Change mobile phone</div>
                  </button>
              )}
            </stepsUi.Row>
          </stepsUi.Grid>
          {canHidePhone && (
              <stepsUi.Row>
                <Checkbox
                    id="hide_phone_number"
                    labelSize="md"
                    {...register("hide_mobile_phone_number")}
                    error={errors?.hide_mobile_phone_number?.message}
                >
                  Hide mobile phone in profile
                </Checkbox>
              </stepsUi.Row>
          )}
          <stepsUi.Row label="Address">
            <InputSmartSearch
                onSelectPlace={(place) => {
                  setValue("street_address", place.address || "");
                  setValue("city", place.city || "");
                  setValue("state", place.state);
                  setValue("zip_code", place.zip || "");
                  setValue("longitude", place.longitude || 0);
                  setValue("latitude", place.latitude || 0);
                }}
                types={["address"]}
                placeholder="Start typing your address"
                className={styles.search}
            />
          </stepsUi.Row>
          <stepsUi.Grid>
            <stepsUi.Row>
              <Input
                  type="text"
                  placeholder=""
                  label="Street Address"
                  {...register("street_address", streetAddressValidator)}
                  error={errors.street_address?.message}
              />
            </stepsUi.Row>
          </stepsUi.Grid>
          <stepsUi.Grid>
            <stepsUi.RowShort>
              <stepsUi.Row>
                <Input
                    type="text"
                    placeholder=""
                    label="City"
                    {...register("city", cityValidator)}
                    error={errors.city?.message}
                />
              </stepsUi.Row>
              <stepsUi.Row>
                <Input
                    type="text"
                    placeholder=""
                    label="Zip"
                    {...register("zip_code", zipCodeValidator)}
                    error={errors.zip_code?.message}
                />
              </stepsUi.Row>
            </stepsUi.RowShort>
            <stepsUi.Row label="State">
              <Controller
                  control={control}
                  name="state"
                  render={({ field }) => (
                      <AppSelect
                          placeholder="Select"
                          options={STATE_OPTIONS}
                          value={STATE_OPTIONS.find(
                              ({ value }) => value === field.value
                          )}
                          onChange={(v) => field.onChange((v as StateOption).value)}
                      />
                  )}
              />
            </stepsUi.Row>
          </stepsUi.Grid>
          <stepsUi.Row>
            <Checkbox
                id="hide_personal_address"
                labelSize="md"
                {...register("hide_personal_address")}
                error={errors?.hide_personal_address?.message}
            >
              Hide home address from public view
            </Checkbox>
          </stepsUi.Row>
          <Button className={styles.editSpecialtiesButton} type="submit">
            Save
          </Button>
        </form>
        <EmailPopup />
      </section>
  );
};