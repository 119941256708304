import cn from "classnames";
import Button, { ButtonProps } from "common/UIKit/Button/Button";

interface Props {
  buttons: ButtonProps[];
  className?: string;
}

export const PatientStepsActions = ({ buttons, className }: Props) => (
  <div className={cn("patient-step__actions", className)}>
    {buttons.map((buttonProps, index) => {
      const { size = "full", className, ...other } = buttonProps;
      return (
        <Button
          key={index}
          size={size}
          className={cn("patient-step__btn", className)}
          {...other}
        />
      );
    })}
  </div>
);
