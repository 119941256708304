import { FC } from "react";

import { useAuth } from "hooks/useAuth";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";

import Button from "common/UIKit/Button";
import { FormatProfileField } from "common/components/FormatProfileField";
import { UserTestimonial } from "common/components/UserTestimonial";

import { ProfileCard } from "./ProfileCard";

import styles from "./PersonalInformationDesktop.module.scss";

import { ReactComponent as Edit } from "assets/images/icons/edit.svg";

interface Props {
  onEdit: () => void;
  onChangePassword: () => void;
}

export const PersonalInformationDesktop: FC<Props> = ({
  onEdit,
  onChangePassword,
}) => {
  useDashboardNavigation("Personal Information", {
    goBack: "pop_path",
  });
  const { user, baseUser } = useAuth();

  return (
    <div className={styles.personalInformation}>
      <div className={styles.profileWrapper}>
        <ProfileCard />
        <div className={styles.wrapper}>
          <div className={styles.info}>
            <div className={styles.infoField}>
              <h3>Full Name</h3>
              <small>
                <FormatProfileField maxLength={40}>
                  {`${user?.user.first_name} ${user?.user.last_name}`}
                </FormatProfileField>
              </small>
            </div>
            <div className={styles.infoField}>
              <h3>Mobile Phone</h3>
              <small>
                <FormatProfileField shouldLimitLength={false}>
                  {user?.user.mobile_phone}
                </FormatProfileField>
              </small>
            </div>
            <div className={styles.infoField}>
              <h3>Email</h3>
              <small>
                <FormatProfileField shouldLimitLength={false}>
                  {user?.user.email}
                </FormatProfileField>
              </small>
            </div>
            <div className={styles.infoField}>
              <h3>Mental Health Role</h3>
              <small>Patient</small>
            </div>
          </div>
          <Button className={styles.iconButton} onClick={onEdit}>
            <Edit /> Edit information
          </Button>
        </div>
      </div>

      {!baseUser?.is_google_user && (
        <div className={styles.backdrop}>
          <div>
            <div className={styles.infoField}>
              <h3>Change password</h3>
            </div>
          </div>
          <Button className={styles.iconButton} onClick={onChangePassword}>
            <Edit /> Change password
          </Button>
        </div>
      )}

      <UserTestimonial />
    </div>
  );
};
