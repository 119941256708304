import React, { forwardRef } from "react";
import cn from "classnames";

import "./Checkbox.scss";

type Props = Omit<React.HTMLProps<HTMLInputElement>, "size" | "children"> & {
  size?: "full" | "auto";
  label?: React.ReactNode;
  labelSize?: "sm" | "md";
  children?: React.ReactNode;
  error?: string;
  type?: "checkbox" | "radio"
};

const Checkbox = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    onChange,
    id,
    name,
    className,
    disabled,
    checked,
    labelSize,
    children,
    error,
    label,
    type = "checkbox",
  } = props;

  return (
    <div className={cn("checkbox", className)}>
      <input
        ref={ref}
        className="checkbox__input"
        onChange={onChange}
        type={type}
        id={id}
        name={name}
        disabled={disabled}
        checked={checked}
      />
      <label
        className={cn(`label-size--${labelSize}`, { "--error": error })}
        htmlFor={id}
      >
        {children || label}
      </label>
    </div>
  );
});

export default Checkbox;
