import { Route, Routes, useNavigate } from "react-router-dom";
import {
  EditPersonalInformation,
  editPersonalInformationPath,
} from "./EditPersonalInformation";
import {
  editPatientInformationPath,
  EditPatientInformation,
} from "./EditPatientInformation";
import { ChangePassword, changePasswordPath } from "./ChangePassword";
import {
  PersonalInformation,
  personalInformationPath,
} from "./PersonalInformation";
import {
  patientInformationPath,
  PatientsInformation,
} from "./PatientsInformation";
import { OverviewMobile } from "./OverviewMobile";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { useDocumentTitle } from "hooks/useDocumentTitle";

export const myAccountPath = "/my-account";

export const MyAccount = () => {
  useDashboardNavigation("My Account", { isMainTab: true });
  const navigate = useNavigate();
  useDocumentTitle("TheraPass - My Account");

  return (
    <Routes>
      <Route
        path="/"
        element={
          <OverviewMobile
            onChangePassword={() => navigate(`.${changePasswordPath}`)}
            onPersonalInformation={() =>
              navigate(`.${personalInformationPath}`)
            }
            onPatientInformation={() => navigate(`.${patientInformationPath}`)}
          />
        }
      />
      <Route
        path={personalInformationPath}
        element={
          <PersonalInformation
            onChangePassword={() => navigate(`.${changePasswordPath}`)}
            onEdit={() => navigate(`.${editPersonalInformationPath}`)}
          />
        }
      />
      <Route path={patientInformationPath} element={<PatientsInformation />} />

      <Route
        path={editPersonalInformationPath}
        element={<EditPersonalInformation />}
      />
      <Route path={changePasswordPath} element={<ChangePassword />} />
      <Route
        path={editPatientInformationPath}
        element={<EditPatientInformation />}
      />
    </Routes>
  );
};
