import { ReactNode } from "react";
import { capitalize } from "lodash";

import { CredentialType } from "types/credential.types";
import {
  Group,
  Credential,
  ALL_TITLES,
  Title,
  PrimaryCredentialType,
} from "types/therapist.types";
import {
  AppointmentDate,
  PreferredPrice,
  PreferredSexuality,
  TherapistPreference,
} from "types/patient.types";
import { PaymentPeriod } from "types/plan.types";

import { ReactComponent as InPerson } from "assets/images/icons/sofa.svg";
import { ReactComponent as Telephone } from "assets/images/icons/mobile.svg";
import { ReactComponent as Videochat } from "assets/images/icons/video.svg";
import { ReactComponent as Textchat } from "assets/images/icons/comment-dots.svg";

import { ReactComponent as ToddlerIcon } from "assets/images/icons/toddler.svg";
import { ReactComponent as ChildrenIcon } from "assets/images/icons/children.svg";
import { ReactComponent as TeenIcon } from "assets/images/icons/teen.svg";
import { ReactComponent as AdultIcon } from "assets/images/icons/adult.svg";
import { ReactComponent as ElderIcon } from "assets/images/icons/elder.svg";

// Options are lists of choices that are static, not fetched; we know the codes (values) for them beforehand
export type ListOption<V = string> = {
  value: V;
  label: string;
  icon?: ReactNode;
};

export type AgeCode =
  | "toddler"
  | "child"
  | "preteen"
  | "teen"
  | "adult"
  | "adults"
  | "elder";
export type Ethnicity =
  | "none"
  | "native_american"
  | "hispanic"
  | "black"
  | "asian"
  | "pacific_islander"
  | "other";
export type Faith =
  | "none"
  | "christian"
  | "fundamentalist_christian"
  | "jewish"
  | "buddhist"
  | "church_of_jesus_christ"
  | "hindu"
  | "islam"
  | "sikh"
  | "spiritual"
  | "other";
export type Availability = "yes" | "no" | "waitlist";
export type TherapyType = "inperson" | "telephone" | "videochat" | "textchat";
export type State =
  | "AL"
  | "AK"
  | "AZ"
  | "AR"
  | "CA"
  | "CO"
  | "CT"
  | "DE"
  | "FL"
  | "GA"
  | "HI"
  | "ID"
  | "IL"
  | "IN"
  | "IA"
  | "KS"
  | "KY"
  | "LA"
  | "ME"
  | "MD"
  | "MA"
  | "MI"
  | "MN"
  | "MS"
  | "MO"
  | "MT"
  | "NE"
  | "NV"
  | "NH"
  | "NJ"
  | "NM"
  | "NY"
  | "NC"
  | "ND"
  | "OH"
  | "OK"
  | "OR"
  | "PA"
  | "RI"
  | "SC"
  | "SD"
  | "TN"
  | "TX"
  | "UT"
  | "VT"
  | "VA"
  | "WA"
  | "WV"
  | "WI"
  | "WY";

export type FaithOption = ListOption<Faith>;
export type EthnicityOption = ListOption<Ethnicity>;
export type GroupOption = ListOption<Group>;
export type TherapyTypeOption = ListOption<TherapyType>;
export type CredentialOption = ListOption<CredentialType>;
export type AgeOption = ListOption<AgeCode>;
export type PriceOption = ListOption<PreferredPrice>;
export type PreferenceOption = ListOption<TherapistPreference> & {
  isSole?: boolean;
};
export type SexualityOption = ListOption<PreferredSexuality> & {
  isSole?: boolean;
};
export type AppointmentDateOption = ListOption<AppointmentDate>;
export type AvailabilityOption = ListOption<Availability>;
export type StateOption = ListOption<State>;

export const TITLE_OPTIONS: ListOption<Title>[] = ALL_TITLES.map((value) => ({
  value: value,
  label: `${capitalize(value)}.`,
}));

export const CREDENTIALS_OPTIONS: ListOption<Credential>[] = [
  { value: "ma", label: "MA" },
  { value: "phd", label: "Phd" },
  { value: "lcsw", label: "LCSW" },
];

export const FAITH_OPTIONS: FaithOption[] = [
  { value: "none", label: "No preference" },
  { value: "hindu", label: "Hindu" },
  { value: "christian", label: "Christian" },
  { value: "islam", label: "Islam" },
  { value: "fundamentalist_christian", label: "Fundamentalist Christian" },
  { value: "sikh", label: "Sikh" },
  { value: "jewish", label: "Jewish" },
  { value: "spiritual", label: "Spiritual but not religious" },
  { value: "buddhist", label: "Buddhist" },
  { value: "other", label: "Other" },
  {
    value: "church_of_jesus_christ",
    label: "Church of Jesus Christ of Latter Day Saints",
  },
];
export const ETHNICITY_OPTIONS: EthnicityOption[] = [
  { value: "none", label: "No Preference" },
  { value: "black", label: "Black/African American" },
  { value: "native_american", label: "Native American" },
  { value: "hispanic", label: "Hispanic/Latino" },
  { value: "asian", label: "Asian" },
  { value: "pacific_islander", label: "Pacific Islander" },
  { value: "other", label: "Other Racial or Ethnic Background" },
];

export const THERAPY_TYPE_OPTIONS: TherapyTypeOption[] = [
  { value: "inperson", label: "In-Person", icon: <InPerson /> },
  { value: "telephone", label: "Phone", icon: <Telephone /> },
  { value: "videochat", label: "Video", icon: <Videochat /> },
  { value: "textchat", label: "Chat", icon: <Textchat /> },
];

export const VERIFIED_CREDENTIAL_OPTIONS: CredentialOption[] = [
  { value: "diploma", label: "Diploma / Degree" },
  { value: "certificate", label: "Certificate" },
  { value: "license", label: "License" },
  { value: "membership", label: "Membership" },
];

export const AGE_OPTIONS: AgeOption[] = [
  { value: "toddler", label: "Toddler", icon: <ToddlerIcon /> },
  { value: "child", label: "Child (6-10)", icon: <ChildrenIcon /> },
  { value: "preteen", label: "Preteen", icon: <TeenIcon /> },
  { value: "teen", label: "Teen", icon: <TeenIcon /> },
  { value: "adult", label: "Adult (18+)", icon: <AdultIcon /> },
  { value: "elder", label: "Senior (65+)", icon: <ElderIcon /> },
];

export const PRICE_OPTIONS: PriceOption[] = [
  {
    label: "Under $90",
    value: "min",
  },
  {
    label: "$90 - $130",
    value: "avg",
  },
  {
    label: "$130 and above",
    value: "max",
  },
  {
    label: "Flex rate",
    value: "flex",
  },
];

export const PREFERENCE_OPTIONS: PreferenceOption[] = [
  {
    label: "No preference",
    value: "none",
    isSole: true,
  },
  {
    label: "I prefer a male therapist",
    value: "male",
  },
  {
    label: "I prefer a female therapist",
    value: "female",
  },
  {
    label: "I prefer a therapist who provides Christian-based therapy",
    value: "christian",
  },
  {
    label: "I prefer an older therapist (45+)",
    value: "older",
  },
  {
    label: "I prefer a non-religious therapist",
    value: "nonreligious",
  },
  {
    label: "I prefer a therapist who speaks Spanish",
    value: "spanish",
  },
  {
    label: "I prefer a therapist of color",
    value: "color",
  },
];

export const SEXUALITY_OPTIONS: SexualityOption[] = [
  {
    value: "none",
    label: "No preference",
    isSole: true,
  },
  {
    value: "straight",
    label: "Straight",
  },
  {
    value: "lesbian",
    label: "Lesbian",
  },
  {
    value: "bisexual",
    label: "Bisexual",
  },
  {
    value: "lgbtq",
    label: "LGBTQIA+",
  },
  {
    value: "gay",
    label: "Gay",
  },
];

export const APPOINTMENT_DATE_OPTIONS: AppointmentDateOption[] = [
  { value: "immediately", label: "Immediately" },
  { value: "today", label: "Today" },
  { value: "next_couple_days", label: "Within the next few days" },
  { value: "none", label: "Not sure" },
];

export const AVAILABILITY_OPTIONS: AvailabilityOption[] = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
  { value: "waitlist", label: "Waitlist" },
];
export const STATE_OPTIONS: StateOption[] = [
  {
    value: "AL",
    label: "Alabama",
  },
  {
    value: "AK",
    label: "Alaska",
  },
  {
    value: "AZ",
    label: "Arizona",
  },
  {
    value: "AR",
    label: "Arkansas",
  },
  {
    value: "CA",
    label: "California",
  },
  {
    value: "CO",
    label: "Colorado",
  },
  {
    value: "CT",
    label: "Connecticut",
  },
  {
    value: "DE",
    label: "Delaware",
  },
  {
    value: "FL",
    label: "Florida",
  },
  {
    value: "GA",
    label: "Georgia",
  },
  {
    value: "HI",
    label: "Hawaii",
  },
  {
    value: "ID",
    label: "Idaho",
  },
  {
    value: "IL",
    label: "Illinois",
  },
  {
    value: "IN",
    label: "Indiana",
  },
  {
    value: "IA",
    label: "Iowa",
  },
  {
    value: "KS",
    label: "Kansas",
  },
  {
    value: "KY",
    label: "Kentucky",
  },
  {
    value: "LA",
    label: "Louisiana",
  },
  {
    value: "ME",
    label: "Maine",
  },
  {
    value: "MD",
    label: "Maryland",
  },
  {
    value: "MA",
    label: "Massachusetts",
  },
  {
    value: "MI",
    label: "Michigan",
  },
  {
    value: "MN",
    label: "Minnesota",
  },
  {
    value: "MS",
    label: "Mississippi",
  },
  {
    value: "MO",
    label: "Missouri",
  },
  {
    value: "MT",
    label: "Montana",
  },
  {
    value: "NE",
    label: "Nebraska",
  },
  {
    value: "NV",
    label: "Nevada",
  },
  {
    value: "NH",
    label: "New Hampshire",
  },
  {
    value: "NJ",
    label: "New Jersey",
  },
  {
    value: "NM",
    label: "New Mexico",
  },
  {
    value: "NY",
    label: "New York",
  },
  {
    value: "NC",
    label: "North Carolina",
  },
  {
    value: "ND",
    label: "North Dakota",
  },
  {
    value: "OH",
    label: "Ohio",
  },
  {
    value: "OK",
    label: "Oklahoma",
  },
  {
    value: "OR",
    label: "Oregon",
  },
  {
    value: "PA",
    label: "Pennsylvania",
  },
  {
    value: "RI",
    label: "Rhode Island",
  },
  {
    value: "SC",
    label: "South Carolina",
  },
  {
    value: "SD",
    label: "South Dakota",
  },
  {
    value: "TN",
    label: "Tennessee",
  },
  {
    value: "TX",
    label: "Texas",
  },
  {
    value: "UT",
    label: "Utah",
  },
  {
    value: "VT",
    label: "Vermont",
  },
  {
    value: "VA",
    label: "Virginia",
  },
  {
    value: "WA",
    label: "Washington",
  },
  {
    value: "WV",
    label: "West Virginia",
  },
  {
    value: "WI",
    label: "Wisconsin",
  },
  {
    value: "WY",
    label: "Wyoming",
  },
];

export const primaryCredentialOptions: ListOption<PrimaryCredentialType>[] = [
  { value: "i_am_licensed", label: "I’m licensed" },
  {
    value: "i_am_pre_licensed",
    label: "I’m pre-licensed or under supervision",
  },
  { value: "i_have_no_license", label: "I have no license" },
];

export const availabilityOptions = {
  in_person_session_availability: "In-Person",
  telephone_session_availability: "Telephone",
  video_session_availability: "Video",
  online_chat_sessions_availability: "Chat Connect",
};

export const paymentPeriodOptions: ListOption<PaymentPeriod>[] = [
  {
    label: "Monthly",
    value: "month",
  },
  {
    label: "Annually",
    value: "year",
  },
];

export const mentalHealthRoles = [
  {
    label: "Certified Nurse Practitioner (CNP)",
    value: "Certified Nurse Practitioner (CNP)",
  },
  {
    label: "Clinical Social Worker (CSW)",
    value: "Clinical Social Worker (CSW)",
  },
  {
    label: "Doctor of Psychology (PSYD)",
    value: "Doctor of Psychology (PSYD)",
  },
  {
    label: "Licensed Addiction Counselor (LAC)",
    value: "Licensed Addiction Counselor (LAC)",
  },
  {
    label: "Licensed Clinical Professional Counselor (LCPC)",
    value: "Licensed Clinical Professional Counselor (LCPC)",
  },
  {
    label: "Licensed Clinical Psychologist (LCP)",
    value: "Licensed Clinical Psychologist (LCP)",
  },
  {
    label: "Licensed Clinical Social Worker - Registered (LCSW-R)",
    value: "Licensed Clinical Social Worker - Registered (LCSW-R)",
  },
  {
    label: "Licensed Clinical Social Worker (LCSW)",
    value: "Licensed Clinical Social Worker (LCSW)",
  },
  {
    label: "Licensed Independent Clinical Social Worker (LICSW)",
    value: "Licensed Independent Clinical Social Worker (LICSW)",
  },
  {
    label: "Licensed Marriage and Family Therapist (LMFT)",
    value: "Licensed Marriage and Family Therapist (LMFT)",
  },
  {
    label: "Licensed Master Social Worker (LMSW)",
    value: "Licensed Master Social Worker (LMSW)",
  },
  {
    label: "Licensed Mental Health Counselor (LMHC)",
    value: "Licensed Mental Health Counselor (LMHC)",
  },
  {
    label: "Licensed Nurse Practitioner (LNP)",
    value: "Licensed Nurse Practitioner (LNP)",
  },
  {
    label: "Licensed Professional Counselor - Supervisor (LPC-S)",
    value: "Licensed Professional Counselor - Supervisor (LPC-S)",
  },
  {
    label: "Licensed Professional Counselor (LPC)",
    value: "Licensed Professional Counselor (LPC)",
  },
  {
    label: "Licensed Professional Counselor Candidate (LPCC)",
    value: "Licensed Professional Counselor Candidate (LPCC)",
  },
  {
    label: "Licensed Psychologist (LP)",
    value: "Licensed Psychologist (LP)",
  },
  {
    label: "Limited Licensed Master Social Worker (LLMSW)",
    value: "Limited Licensed Master Social Worker (LLMSW)",
  },
  {
    label: "Limited Licensed Psychologist (LLP)",
    value: "Limited Licensed Psychologist (LLP)",
  },
  {
    label: "Marriage and Family Counselor (MFC)",
    value: "Marriage and Family Counselor (MFC)",
  },
  {
    label: "Medical Doctor (MD)",
    value: "Medical Doctor (MD)",
  },
  {
    label: "National Certified Counselor (NCC)",
    value: "National Certified Counselor (NCC)",
  },
  {
    label: "Professional Counselor",
    value: "Professional Counselor",
  },
  {
    label: "Psychiatric Mental Health Nurse Practitioner (PMHNP)",
    value: "Psychiatric Mental Health Nurse Practitioner (PMHNP)",
  },
  {
    label: "Psychiatrist (PSY-MD)",
    value: "Psychiatrist (PSY-MD)",
  },
  {
    label: "Social Worker",
    value: "Social Worker",
  },
];

export const states = [
  { value: "AL", label: "Alabama" },
  { value: "AR", label: "Arkansas" },
  { value: "AK", label: "Alaska" },
  { value: "AZ", label: "Arizona" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DC", label: "District of Columbia" },
  { value: "DE", label: "Delaware" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "IA", label: "Iowa" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "MA", label: "Massachusetts" },
  { value: "MD", label: "Maryland" },
  { value: "ME", label: "Maine" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MO", label: "Missouri" },
  { value: "MS", label: "Mississippi" },
  { value: "MT", label: "Montana" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "NE", label: "Nebraska" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NV", label: "Nevada" },
  { value: "NY", label: "New York" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VA", label: "Virginia" },
  { value: "VT", label: "Vermont" },
  { value: "WA", label: "Washington" },
  { value: "WI", label: "Wisconsin" },
  { value: "WV", label: "West Virginia" },
  { value: "WY", label: "Wyoming" },
];

export const months = [
  {
    label: "January",
    value: "01",
  },
  {
    label: "February",
    value: "02",
  },
  {
    label: "March",
    value: "03",
  },
  {
    label: "April",
    value: "04",
  },
  {
    label: "May",
    value: "05",
  },
  {
    label: "June",
    value: "06",
  },
  {
    label: "July",
    value: "07",
  },
  {
    label: "August",
    value: "08",
  },
  {
    label: "September",
    value: "09",
  },
  {
    label: "October",
    value: "10",
  },
  {
    label: "November",
    value: "11",
  },
  {
    label: "December",
    value: "12",
  },
];
