import ReCAPTCHA from "react-google-recaptcha";
import { googleReCaptchaKey } from "utils/env";
import Input from "../../common/UIKit/Input";
import Textarea from "../../common/UIKit/Textarea/Textarea";
import Button from "../../common/UIKit/Button";
import { LandingHeader } from "../Auth/components/LandingHeader/LandingHeader";
import { Footer } from "../Auth/components/Footer/Footer";
import styles from "./ContactUs.module.scss";

export const contactUsPath = "/contact";

export const ContactUs = () => {
  return (
    <div className={styles.root}>
      <LandingHeader />
      <div className={styles.content}>
        <h1 className={styles.contactTitle}>Contact Us</h1>
        <p className={styles.contactSubtitle}>
          We look forward to hearing from you! After receiving your application,
          we'll contact you by email.
        </p>
        <form className={styles.form}>
          <div className={styles.formColumn}>
            <Input className={styles.input} label="Your Name" />
            <Input className={styles.input} label="Your Email" />
            <Input className={styles.input} label="Phone number" />
          </div>
          <div className={styles.formColumn}>
            <Textarea
              className={styles.textarea}
              label="Message"
              placeholder="Type your message here..."
              withoutLength
            />
            <ReCAPTCHA
              className={styles.captcha}
              sitekey={googleReCaptchaKey}
            />
            <Button className={styles.formButton}>Send</Button>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
};
