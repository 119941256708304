import { FC } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import Button from "common/UIKit/Button/Button";
import { LikeButton } from "common/UIKit/LikeButton";

import { MessageButton } from "../MessageButton/MessageButton";
import { StateLicenseListItem } from "../StateLicenseListItem";

import { patientsTherapistPremiumFullPath } from "pages/Patients/PatientsTherapist";

import { OPTIONS_ICONS } from "constants/icons";
import { areaToString, getAvatar, therapistName } from "common/utils";
import { TherapistFeaturedItem } from "types/therapist.types";
import { isChatAccess, isTherapist } from "services/authService";
import { useModal } from "hooks/useModal";
import { useAuth } from "hooks/useAuth";
import { useCommunication } from "hooks/useCommunication";
// import { useDictionaryItemNames } from "hooks/useDictionaryItemNames";

import { FormatProfileField } from "../FormatProfileField";

import { ReactComponent as Verified } from "assets/images/icons/verified.svg";
import { ReactComponent as MapMarker } from "assets/images/icons/map-marker-fill.svg";
import avatar from "assets/images/avatar.png";

import styles from "./TherapistCard.module.scss";

interface Props {
  therapist: TherapistFeaturedItem;
  className?: string;
  isFavorite?: boolean;
  withoutSeeMore?: boolean;
  withoutVerifiedLabel?: boolean;
  roundChat?: boolean;
  onLike?: () => Promise<void>;
  withDescription?: boolean;
}

export const TherapistCard: FC<Props> = ({
  therapist,
  className,
  isFavorite = false,
  withoutSeeMore = false,
  withoutVerifiedLabel = false,
  roundChat,
  onLike,
  withDescription = false,
}) => {
  const {
    id,
    // title,
    first_name,
    last_name,
    primary_credential_is_verified,
    // professional_specialties,
    in_person_session_availability,
    telephone_session_availability,
    video_session_availability,
    online_chat_sessions_availability,
    is_my_favourite,
    personal_statement_short_description,
    verified_state_licenses,
    business_info,
  } = therapist;
  const { open } = useModal("LOGGED_AS_THERAPIST");
  const { user } = useAuth();
  const { openChantConnect } = useCommunication();

  const businessAddress = areaToString(business_info);

  const isLicensesedInState = verified_state_licenses
    .map(({ state }) => state)
    .includes(business_info.state);

  const withStatus =
    therapist &&
    isChatAccess(therapist) &&
    therapist?.online_chat_sessions_availability;

  const availability = {
    in_person_session_availability,
    telephone_session_availability,
    video_session_availability,
    online_chat_sessions_availability,
  };

  const onMessage = () => {
    user && isTherapist(user) ? open() : openChantConnect(therapist);
  };

  // const professionalSpecialtyNames = useDictionaryItemNames(
  //   "ProfessionalSpecialties",
  //   professional_specialties
  // );

  return (
    <>
      <div
        className={classNames(
          styles.root,
          className,
          isFavorite && styles.favorite
        )}
      >
        <Link to={patientsTherapistPremiumFullPath.replace(":id", String(id))}>
          <img
            className={styles.image}
            alt={`${first_name} ${last_name}`}
            src={getAvatar(therapist.photos)?.file || avatar}
          />
        </Link>
        {isFavorite && (
          <LikeButton
            className={styles.likeButton}
            isLiked={is_my_favourite}
            onClick={onLike}
          />
        )}
        <div className={styles.content}>
          <MessageButton
            className={classNames(
              styles.message_btn,
              roundChat && styles.round
            )}
            onClick={onMessage}
            therapist={therapist}
            withStatus={withStatus}
          />
          <div className={styles.title}>
            <Link
              to={patientsTherapistPremiumFullPath.replace(":id", String(id))}
            >
              <p className={styles.name}>
                <FormatProfileField maxLength={40}>
                  {therapistName(therapist)}
                </FormatProfileField>
              </p>
            </Link>
            {primary_credential_is_verified && (
              <div className={styles.verified}>
                <Verified />
                {withoutVerifiedLabel && <span>Verified</span>}
              </div>
            )}
          </div>
          {/* {professionalSpecialtyNames.length > 0 && (
            <p className={cn(styles.text, styles.professionalSpecialtyNames)}>
              {professionalSpecialtyNames.join(", ")}
            </p>
          )} */}

          {businessAddress && isLicensesedInState && (
            <p className={classNames(styles.address, styles.text)}>
              <>
                <MapMarker className={styles.map_marker} />
                <FormatProfileField maxLength={40}>
                  {businessAddress}
                </FormatProfileField>
              </>
            </p>
          )}

          <div className={styles.stateLicenses}>
            {verified_state_licenses.slice(0, 3).map((license) => (
              <StateLicenseListItem
                key={`${license.state}-${license.number}`}
                license={license}
              />
            ))}
          </div>

          <p className={styles.text}>Available Therapy Options:</p>
          <div className={styles.options}>
            {OPTIONS_ICONS.filter(({ name }) => availability[name]).map(
              ({ name, component: Component }) => (
                <div key={name} className={styles.option} data-name={name}>
                  <Component />
                </div>
              )
            )}
          </div>

          {withDescription && (
            <p className={styles.description}>
              <FormatProfileField
                maxLength={200}
                fallbackText="No description added yet"
                description={true}
              >
                {personal_statement_short_description}
              </FormatProfileField>
            </p>
          )}

          {!isFavorite && !withoutSeeMore && (
            <Button
              size="full"
              className={styles.seeMoreButton}
              isLink
              to={patientsTherapistPremiumFullPath.replace(":id", String(id))}
            >
              View Profile
            </Button>
          )}
        </div>
      </div>
    </>
  );
};
