import { Controller } from "react-hook-form";
import { stepsUi } from "../components";
import AppSelect from "common/UIKit/Select/Select";
import Checkbox from "common/UIKit/Checkbox/Checkbox";
import {
  FAITH_OPTIONS,
  ETHNICITY_OPTIONS,
  FaithOption,
} from "constants/options";
import { useTherapistStep5 } from "./useTherapistStep5";
import { ErrorMessage } from "common/UIKit/ErrorMessage";
import ControlledMultiSelect from "common/components/ControlledMultiSelect";
import styles from "./TherapistStep5.module.scss";
import { AgeCardCheckbox } from "common/UIKit/AgeCardCheckbox/AgeCardCheckbox";

export function TherapistStep5() {
  const {
    handleSubmit,
    control,
    actionsProps,
    errors,
    languages,
    alliedGroups,
    isProcessing,
  } = useTherapistStep5();

  return (
    <>
      <stepsUi.Header>Client focus</stepsUi.Header>
      <stepsUi.Form
        handleSubmit={handleSubmit}
        actions={<stepsUi.Actions {...actionsProps} />}
      >
        <AgeCardCheckbox
          className={styles.ageGrid}
          name="ages"
          control={control}
          label="Client Age:"
        />
        <stepsUi.Grid>
          <stepsUi.Row
            label="Faith Organization"
            subLabel="Do you offer special insight for clients of the following faith?"
          >
            <Controller
              name="preferred_faith"
              control={control}
              render={({ field }) => (
                <AppSelect
                  placeholder="Select"
                  options={FAITH_OPTIONS}
                  {...field}
                  value={FAITH_OPTIONS.find((o) => o.value === field.value)}
                  onChange={(newValue) => {
                    const { value } = newValue as FaithOption;
                    field.onChange(value);
                  }}
                  error={errors.preferred_faith?.message}
                />
              )}
            />
          </stepsUi.Row>
          <stepsUi.Row
            label="Groups"
            subLabel="Are you allied with or have experience supporting clients in these groups?"
          >
            <ControlledMultiSelect
              name="allied_groups"
              control={control}
              options={alliedGroups}
              placeholder="Select"
            />
          </stepsUi.Row>
        </stepsUi.Grid>
        <stepsUi.Row label="Ethnicity of Your clients">
          <stepsUi.Grid className={styles.checkboxGrid}>
            <Controller
              control={control}
              name="preferred_ethnicities"
              render={({ field: { value, onChange } }) => (
                <>
                  {ETHNICITY_OPTIONS.map((ethnicity) => (
                    <Checkbox
                      key={ethnicity.value}
                      name={ethnicity.value}
                      className={styles.checkbox}
                      labelSize="md"
                      checked={value?.includes(ethnicity.value)}
                      id={ethnicity.value}
                      onChange={(e) => {
                        const noPreferenceCode = "none";
                        if (
                          ethnicity.value === noPreferenceCode &&
                          e.currentTarget.checked
                        ) {
                          onChange([noPreferenceCode]);
                        } else {
                          const valueWithoutNone = value.filter(
                            (v) => v !== noPreferenceCode
                          );
                          const newValue = e.currentTarget.checked
                            ? [...valueWithoutNone, ethnicity.value]
                            : valueWithoutNone.filter(
                                (v) => v !== ethnicity.value
                              );

                          onChange(newValue);
                        }
                      }}
                    >
                      {ethnicity.label}
                    </Checkbox>
                  ))}
                </>
              )}
            />
            <ErrorMessage>{errors.preferred_ethnicities?.message}</ErrorMessage>
          </stepsUi.Grid>
        </stepsUi.Row>
        <stepsUi.Grid>
          <stepsUi.Row label="Languages Spoken (besides English)">
            <ControlledMultiSelect
              name="preferred_languages"
              control={control}
              options={languages}
              isDisabled={isProcessing}
              placeholder="Start typing language"
            />
          </stepsUi.Row>
        </stepsUi.Grid>
      </stepsUi.Form>
    </>
  );
}
