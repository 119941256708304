import { useEffect } from "react";
import { useSnapshot } from "valtio";
import { Link, useParams, Navigate } from "react-router-dom";
import isEmail from "validator/es/lib/isEmail";
import Button from "common/UIKit/Button/Button";
import InputPassword, {
  SPECIAL_CHAR_REGEXP,
} from "common/UIKit/InputPassword/InputPassword";
import Input from "common/UIKit/Input/Input";
import Checkbox from "common/UIKit/Checkbox/Checkbox";
import { ErrorMessage } from "common/UIKit/ErrorMessage";
import { AuthLayout } from "pages/Auth/components/AuthLayout";
import { isUserType } from "services/authService";
import useSignUpPatient from "./useSignUp";
import { generalLogicService } from "services/generalLogicService";
import { loginPath } from "../../commonLinks";
import { UserType } from "types/auth.types";
import { AccessController } from "common/components/Layout/AccessController";
import cn from "classnames";
import { WrongRoleModal } from "../components/WrongRoleModal";
import { InputPhone } from "common/UIKit/InputPhone/InputPhone";
import styles from "../AuthInput.module.scss";

type Props = {
  userType: UserType;
};

const patientMessages = {
  postTitle: "User Registration",
  rightTitle: "Find the best match \nfor your therapy",
  consent_accepted:
    "I agree that I am soliciting the services of a licensed mental health professional using the telephone or internet as a “point-of-service” modality to engage with, and visit the therapist, and further agree to abide by the ethical and legal guidelines prescribed by the applicable state of licensure. I give my consent to communicate with the therapist by text message. Text messaging is conducted on a wireless carrier's (Verizon, AT&T, Sprint, TMO) network that lacks encryption and where there exists risk of unauthorized disclosure. Message & data rates may apply. Text STOP to opt out.",
};
const therapistMessages = {
  postTitle: "Therapist Registration",
  rightTitle: "Expand your \nprofessional footprint",
  consent_accepted:
    "I give my consent to communicate by text message. Text messaging is conducted on a wireless carrier's (Verizon, AT&T, Sprint, TMO) network that lacks encryption and where there exists risk of unauthorized disclosure. Message & data rates may apply. Text STOP to opt out",
};

function SignUpPage({ userType }: Props) {
  const { register, handleSubmit, errors, isLoading, dirtyFields, control } =
    useSignUpPatient();
  const { error } = useSnapshot(generalLogicService);
  const messages = userType === "patient" ? patientMessages : therapistMessages;
  const shouldDisplayError = error?.type === "auth";

  useEffect(() => {
    return () => {
      shouldDisplayError && generalLogicService.clearError();
    };
  }, []);

  return (
    <AuthLayout
      signTitle="Welcome to TheraPass"
      signSupTitle={userType === "patient" ? "" : "TM"}
      error={shouldDisplayError ? error?.message : undefined}
      signPostTitle={messages.postTitle}
      signRightTitle={messages.rightTitle}
      className={cn(userType === "therapist" && `title-therapist`)}
    >
      <form
        id="signin"
        action=""
        className="sign-form"
        onSubmit={handleSubmit}
        noValidate
      >
        {errors.global && <ErrorMessage>{errors.global}</ErrorMessage>}
        <input type="hidden" {...register("type", { value: userType })} />
        <Input
          id="first_name"
          type="text"
          className={styles.signInput}
          placeholder=""
          label="First Name"
          {...register("firstName", { required: "Required field" })}
          error={errors.firstName?.message}
        />
        <Input
          id="last_name"
          type="text"
          className={styles.signInput}
          placeholder=""
          label="Last Name"
          {...register("lastName", { required: "Required field" })}
          error={errors.lastName?.message}
        />
        <Input
          id="email"
          type="email"
          className={styles.signInput}
          placeholder=""
          label="Email"
          {...register("email", {
            required: "Required field",
            validate: (value) => isEmail(value) || "Invalid email",
          })}
          error={errors.email?.message}
        />
        {/*<Input
          id="phone"
          type="tel"
          className={styles.signInput}
          placeholder=""
          label="Mobile phone"
          {...register("phone", { required: "Required field" })}
          error={errors.phone?.message}
        />*/}
        <InputPhone
          id="phone"
          name="mobile_phone"
          control={control}
          label="Mobile phone"
          rules={{ required: "Required field" }}
        />
        <InputPassword
          id="password"
          validateStrength={dirtyFields.password}
          label="Password"
          placeholder=""
          {...register("password", {
            required: "Required field",
            minLength: { value: 8, message: "Min length is 8" },
            validate: {
              hasAlpha: (value) =>
                !!value.match(/\w/g) || "Need at least one letter",
              hasNumeric: (value) =>
                !!value.match(/\d/g) || "Need at least one number",
              hasSymbols: (value) =>
                !!value.match(SPECIAL_CHAR_REGEXP) || "Need special sign",
            },
          })}
          error={errors.password?.message}
        />
        <Checkbox
          id="terms"
          className={cn(
            "sign-checkbox",
            styles.signCheckbox,
            styles.signCheckboxWrapper
          )}
          {...register("terms", { required: "You need to agree" })}
          error={errors.terms?.message}
        >
          <span>
            I agree to Therapass{" "}
            <Link to="/terms" className="g-underline">
              Terms of Use
            </Link>{" "}
            and acknowledge the{" "}
            <Link to="/privacy" className="g-underline">
              Privacy Policy
            </Link>
            .
          </span>
        </Checkbox>
        <Checkbox
          id="consent_accepted"
          className={cn(
            "sign-checkbox",
            styles.signCheckbox,
            styles.signCheckboxWrapper
          )}
          {...register("consent_accepted", { required: "You must agree" })}
          error={errors.consent_accepted?.message}
        >
          <span>{messages.consent_accepted}</span>
        </Checkbox>
        <Button
          type="submit"
          className="sign-submit"
          size="full"
          isLoading={isLoading}
        >
          Get started
        </Button>
        {/*<GoogleOathButton userType={userType} setGlobalError={setGlobalError}>*/}
        {/*  Sign up with Google*/}
        {/*</GoogleOathButton>*/}
        <p className={cn("sign-already", styles.signAlready)}>
          Already have an account?{" "}
          <Link to={`/${userType}${loginPath}`} className="g-underline">
            Log in
          </Link>
        </p>
      </form>
      <WrongRoleModal userType={userType} />
    </AuthLayout>
  );
}

export function SignUp() {
  const { userType } = useParams();

  if (isUserType(userType)) {
    return (
      <AccessController unauthorized="allowed">
        <SignUpPage userType={userType} />
      </AccessController>
    );
  }

  return <Navigate to="/" />;
}
