import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { authService } from "services/authService";
import { step1FullPath as patientStep1FullPath } from "pages/Patients/PatientSteps/PatientStep1";
import { congratulationsFullPath } from "pages/Therapists/Congratulations";
import { useAuth } from "hooks/useAuth";
import { AxiosResponse } from "axios";

type VerificationStatus = "verifying" | "verified" | "error";

export const useVerification = () => {
  const { search } = useLocation();
  const { user, authStatus } = useAuth();
  const [error, setError] = useState<string>();

  const [verificationStatus, setVerificationStatus] =
    useState<VerificationStatus>("verifying");

  const navigate = useNavigate();

  useEffect(() => {
    if (authStatus === "loggedIn" && user && user.user.is_email_confirmed) {
      setTimeout(() => {
        navigate(
          user?.user.therapist ? congratulationsFullPath : patientStep1FullPath
        );
      }, 5000);
    }
  }, [authStatus, user]);

  useEffect(() => {
    (async () => {
      try {
        const searchParams = new URLSearchParams(search);
        const token = searchParams.get("token");
        const email = searchParams.get("email");

        if (token && email) {
          setVerificationStatus("verifying");
          await authService.verifyEmail(token, email);
          setVerificationStatus("verified");
        } else {
          setError("No token or email");
          setVerificationStatus("error");
          throw Error("no token or email");
        }
      } catch (error) {
        const { data } = error as AxiosResponse<{ errors?: [] }>;

        setError(data.errors?.join(".\n"));
        setVerificationStatus("error");
      }
    })();
  }, [search, setVerificationStatus, navigate]);

  return {
    verificationStatus,
    error,
  };
};
