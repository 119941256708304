import { Navigate, useParams } from "react-router-dom";

interface Props {
  withId?: boolean;
  to: string;
}

export const Redirect = ({ to, withId }: Props) => {
  const { id } = useParams();

  return <Navigate to={`${to}${withId ? `/${id}` : ""}`} />;
};
